import styled,{keyframes} from 'styled-components';

const transformAnimation = keyframes`
  0% {
  width: 52px;
  height: 52px;
  }
  50%{
    width: 80px;
    height: 80px;
    filter: blur(1px);

  }
  100% {
    width: 52px;
    height: 52px;
  }
`;
const transformAnimationTwo = keyframes`
  0% {
  
  }
  50%{
     width: 4px;
      height: 4px;

  }
  100% {
    
  }
`; 

const Circle = styled.div<{ color: string }>`
 background-color:${props => props.color};
 animation: ${transformAnimationTwo} 1.2s infinite;
 width:8px;
 height:8px;
 border-radius:50%;
 position:absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
`;
const BigCircle=styled.div<{ color: string }>`
animation: ${transformAnimation} 1.2s infinite;
position:absolute;
background:none;
width:100px;
height:100px;
border-radius: 50%;
outline: 8px solid ${props => props.color};

top: 50%;
left: 50%;
transform: translate(-50%, -50%);

`;
const Conteiner=styled.div`
 position:relative;
 background:none;
 width:140px;
 height:140px;
`
interface ColorProps{
  color: string;
  pago: boolean;
}
const LoaderCircle: React.FC<ColorProps> = ({color,pago}) => {
  return (<div style={{display:'flex',backgroundColor:'white',width:'100vw',height:'100vh',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
    <Conteiner>
    <BigCircle color={color} /> 
    <Circle color={color}/>
    </Conteiner>
    <div style={{maxWidth:'393px',textAlign:'center'}}>
    {pago?<> <p style={{fontSize:'16px',color:'#727070',fontWeight:'700'}}> Será redirigido a la pasarela de pago. </p> 
    <p style={{fontSize:'16px',color:'#727070',fontWeight:'500'}}> No actualice la página ni haga clic en el botón "atrás" o "cerrar" de su navegador. </p>
    </> :
    <p style={{fontSize:'16px',color:'#727070',fontWeight:'700'}}> Cargando, por favor espere. </p> }
    </div>
  </div>);
};

export default LoaderCircle;