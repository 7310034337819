import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import ProfilePic from '../../Components/ProfilePic';
import leftArrow from '../../../assets/left-arrow.svg';
import { useNavigate } from 'react-router-dom';
import search from '../../../assets/search-icon.svg';
import filter from '../../../assets/filter-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SET_HELPERS } from '../../../State/redux/constants/helpers';
import { instance } from '../../../Providers/api';
//import Alert from '../../Components/Alert';
//import successLogin from '../../../assets/login-success.svg';

const Container = styled.div`
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
    //overflow-y: scroll;
    color: #727070;
    background-color: #F2F2F2;
`;

const Section = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #e7e7e7;
    position: relative;
`;

const TopBar = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 15px;
`;

const Navigation = styled.div`
    display: flex;
    width: 100%;
    margin-top: 15px;
`;

const Title = styled.span`
    font-size: 20px;
    margin-left: 15px;
    font-weight: 800;
`;

const BodyContainer = styled.div`
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
    display: flex;
    padding-top: 20px;
    width: 100%;
    justify-content: top;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 160px);
    margin-top: 30px;
`;

const Button = styled.button((props: any) => `
    margin-top: 20px;
    background-color: ${props.isDisabled ? localStorage.getItem('color') + 'a6' : localStorage.getItem('color')};
    color: #fff;
    width: 260px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`);

const ServiceButton = styled.button((props: any) => `
    width: 120px;
    height: 20px; 
    outline: none; 
    border: ${'1px solid ' + localStorage.getItem('color')};
    border-radius: 16px;
    margin-right: 10px;
    background-color: ${props.services ? localStorage.getItem('color') : 'white'};
    color: ${props.services ? 'white' : localStorage.getItem('color')};
`);

const SearchContainer = styled.div`
    border: 1px solid #727070;
    border-radius: 5px;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    width: 80%;
    margin-right: 20px;
`;

const ServicesContainer = styled.div`
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
`;

const Service = styled.div((props: any) => `
    max-width: 100%;
    border: 1px solid #424242;
    border-radius: 5px;
    margin: 5px 0;
    padding: 10px;
    display: flex;
    align-items: center;
    color: ${props.selected ? 'white' : '#424242'};
    background-color: ${props.selected ? '#424242' : 'transparent'};
`);

const PromoDetail = styled.div((props: any) => `
    height: ${props.isShow ? '120px' : '0'};
    flex-direction: column; 
    display: ${props.isShow ? 'flex' : 'none'};
    //justify-content: center;
    align-items: center;
    max-width: 100%;
    text-align: center;
    border: 1px solid #424242;
    padding: ${props.isShow ? '10px 0' : '0'};
    border-radius: 5px;
    //overflow-y: scroll;
`);

const CatContainer = styled.div`
    width: 80%;
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    //overflow-y: scroll;
`;

const Category = styled.div((props: any) => `
    max-width: 100%;
    border: 1px solid ${localStorage.getItem('color')};
    border-radius: 5px;
    margin: 5px 10px 5px 0;
    padding: 10px;
    display: flex;
    align-items: center;
    color: ${props.selected ? 'white' : localStorage.getItem('color')};
    background-color: ${props.selected ? localStorage.getItem('color') : 'transparent'};
`);

// const promotions = [
//     {promo: true, id: 0, nombre: 'Pack dama', precio: '55.00', detalle: [{id: 0, nombre: 'Corte dama'}, {id: 1, nombre: 'Manicure'}]},
//     {promo: true, id: 1, nombre: 'Pack caballlero', precio: '45.00', detalle: [{id: 0, nombre: 'Corte caballero'}, {id: 1, nombre: 'Corte barba'}]},
//     {promo: true, id: 2, nombre: 'Pack cuidado', precio: '35.00', detalle: [{id: 0, nombre: 'Manicure'}, {id: 1, nombre: 'Pedicure'}]},
//     {promo: true, id: 3, nombre: 'Pack laciado', precio: '120.00', detalle: [{id: 0, nombre: 'Laciado brasilero'}, {id: 1, nombre: 'Botox capilar'}]},
// ];

const Servicios = () => {

    const history = useNavigate();
    const [isDisabled, setIsDisabled] = useState(true);
    const helpers = useSelector((state: any) => state.helpers);
    const [toggleServices, setToggleServices] = useState(false);
    const dispatch = useDispatch();
    const [servicios, setServicios] = useState<any>([]);
    const [promociones, setPromociones] = useState<any>([]);
    const [filtered, setFiltered] = useState<any>([]);
    const [buscar, setBuscar] = useState<any>('');
    const logo = localStorage.getItem('logo');
    const [toggleCat, setToggleCat] = useState(false);
    const [categories, setCategories] = useState<any>();
    const [selectedCats, setSelectedCats] = useState<any>([]);
    //const [alert, setAlert] = useState(false);
    //const [serviceCopy, setServiceCopy] = useState<any>({});

    useLayoutEffect(() => {
        instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
        dispatch({type: SET_HELPERS, payload: {stylistSelected: '', hour: '', day: ''}});
        instance.post('api/v1/Categoria/listar', {
            idEmpresa: helpers.companyId,
        })
        .then(res => setCategories([...res.data.data]));
        instance.get(`api/v1/Servicios/${helpers.companyId}/${helpers.locationSelected?.id_sede}`)
        .then(res => {setServicios([...res.data.data]);});
        instance.post('api/v1/Promociones/listarPromocion', {
            id_local: helpers.locationSelected?.id_sede,
            id_empresa: helpers.companyId,
        })
        .then(res => {(res.data.data.length === 0 || helpers.locationSelected.tipoPromocionOServicios === 3) && setToggleServices(true); setPromociones([...res.data.data]);});
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(helpers.locationSelected.tipoPago === 3) {
            dispatch({type: SET_HELPERS, payload: {serviceChosen: {
                idservicio: -1,
                nombre: "Pago adelantado",
                precio: helpers.locationSelected?.montoParcialPago,
            }}});
            history('/reserva/fecha');
        }
        else {
            dispatch({
                type: SET_HELPERS,
                payload: {
                    serviceChosen: {},
                }
            });
            //setServiceCopy({});
            setIsDisabled(true);
        }
    //eslint-disable-next-line
    }, []);

    useEffect(() => {
        // serviceCopy && ((serviceCopy.idPromocion && !helpers.serviceChosen.idPromocion)
        // || (!serviceCopy.idPromocion && helpers.serviceChosen.idPromocion))
        // ? setAlert(true) : setAlert(false);
        //setServiceCopy(helpers.serviceChosen);
    //eslint-disable-next-line
    }, [helpers.serviceChosen]);

    const capitalize = (s: string) => {
        return s.split(' ').map((e: any) => e[0] + e.slice(1).toLowerCase()).join(' ');
    };

    useEffect(() => {
        if(buscar.length > 2) {
            if(toggleServices) {
                setFiltered([...servicios.filter((s: any) => s.nombre.toLowerCase().includes(buscar.toLowerCase()))]);
            }
            else setFiltered([...promociones.filter((p: any) => p.nombrePromocion.toLowerCase().includes(buscar.toLowerCase()))]);
        }
        else {
            if(toggleServices) {setFiltered([...servicios]);}
            else {setFiltered([...promociones]);}
        }
    //eslint-disable-next-line
    }, [buscar, toggleServices, servicios, promociones]);

    return (
        <>
        {/* {alert ? <Alert setShow={setAlert} title='Solo puede seleccionar una promoción o servicio. Si selecciona otra opción, su selección anterior se perderá.' image={successLogin}/> : null} */}
        <Container>
            <Section>
                <TopBar>
                    <img src={logo?.toString() || ''} alt='logo' style={{height: '30px', marginLeft: '40px'}} onClick={() => {history(`/${helpers.slug}`)}}/>
                    <div style={{position: 'absolute', right: '20px'}}><ProfilePic/></div>
                </TopBar>
                <Navigation>
                    <img src={leftArrow} style={{width: '10px', marginLeft: '15px'}} alt='back' onClick={() => { toggleCat ? setToggleCat(false) : (helpers.idLocation || (helpers.cities?.length === 1 && helpers.locations?.length === 1) ? history(`/${helpers.slug}`) : history('/reserva/sedes'))}}/>
                    <Title>
                        Elige una promoción o<br/>
                        servicio
                    </Title>
                </Navigation>
                <BodyContainer>
                    { !toggleCat ?
                    <>
                        <ServicesContainer>
                            <div style={{display: 'flex'}}>
                                <SearchContainer>
                                    <img src={search} alt='search' style={{height: '22px'}}/>
                                    <input type='text' value={buscar} onChange={(e: any) => {setBuscar(e.target.value);}} placeholder='Buscar' style={{outline: 'none', border: 'none', fontSize: '18px', padding: '0 8px', width: '90%'}}/>
                                </SearchContainer>
                                <img src={filter} alt='filter' style={{visibility: toggleServices ? 'visible' : 'hidden',}} onClick={() => {setToggleCat(true)}}/>
                            </div>
                            {
                                promociones.length > 0 && servicios.length > 0 && helpers.locationSelected?.tipoPromocionOServicios === 1 ?
                                <div style={{display: 'flex', marginTop: '20px'}}>
                                    <ServiceButton onClick={() => {setToggleServices(false)}}
                                        //@ts-ignore
                                        services={!toggleServices}
                                    >
                                        Promociones
                                    </ServiceButton>
                                    <ServiceButton onClick={() => {setToggleServices(true)}}
                                        //@ts-ignore
                                        services={toggleServices}
                                    >
                                        Servicios
                                    </ServiceButton>
                                </div>
                                : null
                            }
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px', overflowY: 'scroll', height: '320px'}}>
                                {filtered?.length > 0 ?
                                    !toggleServices
                                    ? filtered?.map((p: any, i: any) =>
                                        <div key={i}>
                                            <Service key={'a' + i}
                                                //@ts-ignore
                                                selected={helpers.serviceChosen.idPromocion === p.idPromocion}
                                                onClick={() => {dispatch({type: SET_HELPERS, payload: {serviceChosen: p}}); setIsDisabled(false);}}>
                                                <span>{capitalize(p?.nombrePromocion || '')}</span>
                                                <span style={{marginLeft: 'auto', height: '16px', whiteSpace: 'nowrap'}}>{`S/ ${p?.precioPromocion?.toString().includes('.') ? p.precioPromocion : p.precioPromocion + '.00'}`}</span>
                                            </Service>
                                            <PromoDetail key={'b' + i}
                                                //@ts-ignore
                                                isShow={helpers.serviceChosen.idPromocion === p.idPromocion}
                                            >
                                                {p?.serviciosPromocion?.map((d: any, i: any) => <span key={'c' + i}>{capitalize(d?.nombreServicio || '')}</span>)}
                                                <span style={{fontWeight: '800', fontSize: '12px', marginTop: '10px'}}>{'*' + (p?.terminos || 'Sujeto a términos y condiciones')}</span>
                                                {/* <Button
                                                    //@ts-ignore
                                                    isDisabled={!isDisabled}
                                                    disabled={!isDisabled}
                                                    onClick={() => {setIsDisabled(false);}}
                                                >
                                                    {isDisabled ? '¡Lo quiero!' : '¡Lo tengo!'}</Button> */}
                                            </PromoDetail>
                                        </div>)
                                    : filtered?.map((s: any, i: any) => 
                                        <Service key={'d' + i}
                                            //@ts-ignore
                                            selected={helpers.serviceChosen.idservicio === s.idservicio}
                                            onClick={() => {dispatch({type: SET_HELPERS, payload: {serviceChosen: s}}); setIsDisabled(false);}}>
                                            <span>{capitalize(s?.nombre || '')}</span>
                                            <span style={{marginLeft: 'auto', height: '16px', whiteSpace: 'nowrap'}}>{`S/ ${s?.precio?.toString().includes('.') ? s.precio : s.precio + '.00'}`}</span>
                                        </Service>)
                                    : <p style={{textAlign: 'center'}}>No hay {toggleServices ? 'servicios' : 'promociones'} disponibles.</p>
                                }
                            </div>
                        </ServicesContainer>
                        <div style={{justifySelf: 'end', display: 'flex', flexDirection: 'column', marginBottom: '10px'}}>
                            <Button
                                //@ts-ignore
                                isDisabled={isDisabled}
                                disabled={isDisabled}
                                onClick={() => {history('/reserva/fecha')}}
                            >
                                Elegir</Button>
                        </div>
                    </> :
                    <>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontSize: '18px', textAlign: 'left', margin: '0', marginRight: '30px', marginBottom: ''}}>
                                Seleccione una o varias categorías
                            </span>
                            <span style={{fontSize: '18px'}} onClick={() => setToggleCat(false)}>x</span>
                        </div>
                        <CatContainer>
                            <Category
                                //@ts-ignore
                                selected={categories.length === selectedCats.length} 
                                onClick={() => {setSelectedCats([...categories])}}
                            >
                                Todas
                            </Category>
                            <Category
                                //@ts-ignore
                                selected={selectedCats.length === 0}
                                onClick={() => {setSelectedCats([])}}
                            >
                                Ninguna
                            </Category>
                            {categories.map((c: any, i: any) =>
                            <Category key={i}
                                onClick={() => {
                                    selectedCats.map((cat: any) => cat.id_categoria).includes(c.id_categoria)
                                    ? setSelectedCats(selectedCats.filter((cat: any) => cat.id_categoria !== c.id_categoria))
                                    : setSelectedCats([...selectedCats, c])}}
                                //@ts-ignore
                                selected={selectedCats.map((cat: any) => cat.id_categoria).includes(c.id_categoria)}
                            >
                                {capitalize(c.nombre_categoria)}
                            </Category>)}
                        </CatContainer>
                        <div style={{justifySelf: 'end', display: 'flex', marginTop: 'auto'}}>
                            <Button
                                //@ts-ignore
                                isDisabled={false}
                                disabled={false}
                                onClick={() => {
                                    if(selectedCats.length > 0) {
                                        setServicios([]);
                                        var serviciosCategorias: any = [];
                                        selectedCats.map(async(c: any) => {
                                        await instance.get(`api/v1/Servicios/${helpers.companyId}/${helpers.locationSelected.id_sede}/${c.id_categoria}`)
                                        .then((res: any) => {serviciosCategorias = [...serviciosCategorias, ...res.data.data]});
                                        setServicios([...serviciosCategorias]);
                                        return null;
                                        });
                                    }
                                    else {
                                        instance.get(`api/v1/Servicios/${helpers.companyId}/${helpers.locationSelected.id_sede}`)
                                        .then((res: any) => {setServicios([...res.data.data])})
                                    }
                                    setToggleCat(false);
                                }}
                            >
                                Aceptar</Button>
                        </div>
                    </>}
                </BodyContainer>
            </Section>
        </Container>
        </>
    )
}

export default Servicios;