import React from 'react';
import styled from 'styled-components';
import ProfilePic from '../../Components/ProfilePic';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import powered from '../../../assets/powered-by-Viuty.svg';
import newServiceIcon from '../../../assets/new-service-icon.svg';
import newCalendarIcon from '../../../assets/new-calendar-icon.svg';
import newHourIcon from '../../../assets/new-hour-icon.svg';
import newTimerIcon from '../../../assets/new-timer-icon.svg';
import { ReactComponent as RegisterCheked } from '../../../assets/register-check.svg'

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
`;


const Section = styled.div<{ mobile?: boolean }>`
    background-color: #ffffff;
    font-family: 'Mulish';
    width: 100%;
    max-width: ${props => props.mobile ? '328px' : '354px'} ;
    font-size: 18px;
    display: flex;
    flex-direction: column; 
    margin: auto;
`;
const Comentario = styled.p`
text-align: center;
font-size: 14px;
font-family: 'Mulish';
font-weight: 600;
`
const Title = styled.p`
text-align: center;
font-size: 16px;
font-family: Mulish;
font-weight: 700;
`

const TopBar = styled.div`
    z-index: 3;
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    height: 74px;
    border-bottom: 1px solid #000000;
`;


const BodyContainer = styled.div((props: any) => `
    display: flex;
    padding-top: 20px;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .formatoValue{
        min-height: 36px;
        display: flex;
        flex-direction: row;
        margin-bottom: ${window.innerWidth < 700 ? '12px' : '20px'};
        margin-top: ${window.innerWidth < 700 ? '0px' : '20px'};
        gap: 8px;
       p{
        font-size: 14px;
        font-weight: 400;
        padding: 0;
        margin: 0;
       }
       span{
        font-size: 18px;
        font-weight: 700;
        padding: 0;
        margin: 0;
       }
    }
    .imgContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 23px;
            height: 23px;
        }
        min-width: 34px;
       }
       .imgPerfil{
        width: 23px;
        height: 23px;
        border-radius: 50%;
        }
        .imgSinPerfil{
            background: linear-gradient(270deg, #B5B5B5 0%, #D9D9D9 100%);
            width: 23px;
            height: 23px;
            border-radius: 50%;
        }
        .largeImage{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 23px;
            height: 23px;
            margin-right: 8px;
        }
        width: auto; 
        }
`);

const Button = styled.button((props: any) => `
    margin-top: 20px;
    background-color: ${localStorage.getItem('color')};
    color: #fff;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    text-align: center;
  font-size: 14px;
  font-family: 'Mulish';
  font-weight: 600;
`);


const Confirmacion = () => {

    const logo = localStorage.getItem('logo');
    const history = useNavigate();
    const helpers = useSelector((state: any) => state.helpers);
    const [searchParams] = useSearchParams();


    const capitalize = (cadena: string) => {
        return cadena.toUpperCase()
        //Despues deberia hacer que separe por espacios y capitalice cada palabra
    };

    const convertTime = (minutos: number | null | undefined): string => {
        if (minutos === null || minutos === undefined) {
            return "No se encontró un tiempo aproximado";
        }

        const horas: number = Math.floor(minutos / 60);
        const minutosRestantes: number = minutos % 60;

        if (horas > 0) {
            if (minutosRestantes > 0) {
                return `${horas}h y ${minutosRestantes}min`;
            } else {
                return `${horas}h`;
            }
        } else {
            return `${minutos}min`;
        }
    };

    const statusPayment = searchParams.get('code');

    return (<div style={{ backgroundColor: '#ffffff' }}>
        <Container>
            <TopBar>
                <div style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={logo?.toString() || ''} alt='logo' style={{ height: '30px', marginLeft: '40px' }} onClick={() => { history(`/${helpers.slug}`) }} />
                    <div style={{ position: 'absolute', right: '20px' }}><ProfilePic /></div>
                </div>
            </TopBar>
            <Section>

                <BodyContainer>
                    {(statusPayment === '200')
                        ? <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                <RegisterCheked fill={helpers.color} />
                            </div>
                            <div>
                                <Title>¡Tu reserva ha sido realizada!</Title>
                                <Comentario>Te enviaremos un recordatorio vía correo electrónico cuando tu cita esté cerca a la fecha de reserva.</Comentario>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '246px', margin: `${window.innerWidth < 700 ? 'none' : 'auto'}` }}>

                                <div className='formatoValue'>
                                    <div className='imgContainer'>
                                        <img src={newServiceIcon} alt="service" />
                                    </div>
                                    <div>
                                        <p>Servicio</p>
                                        <span>{helpers.serviceChosen.idPromocion ? capitalize(helpers.serviceChosen.nombrePromocion) : capitalize(helpers.serviceChosen.nombre)}</span>
                                    </div>
                                </div>
                                <div className='formatoValue'>
                                    <div className='imgContainer'>
                                        <img src={newCalendarIcon} alt="" />
                                    </div>
                                    <div>
                                        <p>Fecha</p>
                                        <span>{`${('0' + helpers.day?.numero).slice(-2)}/${('0' + helpers.day?.mes).slice(-2)}/${helpers.day?.año}`}</span>
                                    </div>
                                </div>
                                <div className='formatoValue'>
                                    <div className='imgContainer'>
                                        <img src={newHourIcon} alt='time' />
                                    </div>
                                    <div>
                                        <p>Hora</p>
                                        <span>{`${helpers.hour} hrs.`} </span>
                                    </div>
                                </div>
                                <div className='formatoValue'>
                                    <div className='imgContainer'>
                                        <div className='imgSinPerfil'></div>
                                    </div>
                                    <div>
                                        <p>Atendido por:</p>
                                        <span>{`Estilista: ${capitalize(helpers.stylistSelected.nombreEmpleado)}`}</span>
                                    </div>
                                </div>
                                <div className='formatoValue'>
                                    <div className='imgContainer'>
                                        <img src={newTimerIcon} alt='time' />
                                    </div>
                                    <div>
                                        <p>Duracíon</p>
                                        {convertTime(helpers.serviceChosen.tiempoEnMinutos)}{" "}
                                        <span></span>
                                    </div>
                                </div>
                            </div>

                            <Button onClick={() => history(`/${helpers.slug}`)}>Volver al centro de reservas</Button>
                        </div>
                        : <>
                            <Title>¡No se pudo realizar la reserva!</Title>
                            <Comentario>Al parecer el estilista ya no se encuentra disponible.</Comentario>

                            <Button onClick={() => history(`/${helpers.slug}`)}>Volver al centro de reservas</Button> </>}

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <a href='https://viutify.com' target='_blank' rel='noreferrer'>
                            <img src={powered} alt='powered by'
                                style={{

                                    height: '16px',
                                    marginTop: '42px',
                                }}
                            />
                        </a>
                    </div>
                </BodyContainer>
            </Section>
        </Container>
    </div>)
}

export default Confirmacion;