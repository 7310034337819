import axios from "axios";

//export const URL_REQ = 'https://apireservasprod.viutifyapp.com/';
export const URL_REQ = 'https://prod-api-reservas.viuti.io/';
//export const URL_REQ = 'https://api-dev-reservas-web.viutifyapp.com/';
export const URL_FRONT= 'https://reservas.viuti.io/'/* 'https://web-reservas-qa.viutifyapp.com/' */
//https://api-dev-reservas-web.viutifyapp.com/api/v1/LoginUsuario/google
const environment = process.env.REACT_APP_ENV;
const baseUrl =
  environment === "production" || environment === "qa" ? URL_REQ : URL_REQ
export const instance = axios.create({
  baseURL: baseUrl,
});
