import React from 'react';
import Routes from "./Nueva Iteración/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./Nueva Iteración/Redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import Loader from './Components/elements/Loader/Loader';



const App2 = () => {
    return (
        
        <Router>
            <Provider store={store}>
                <PersistGate loading={<Loader/>} persistor={persistor}>
                    <Routes/>
                </PersistGate>
            </Provider>
        </Router>
    )
}

export default App2;