import styled from "styled-components";

const Button = styled.button`
  cursor: pointer;
  font-size: 12px;
  padding: 10px 15px;
  border: none;
  font-family: Mulish;
  font-weight: 800;
  color: var(--carpenter-core-color-light-on-primary);
  background: var(--carpenter-core-color-light-primary);
  border-radius: 100px;

  @media (min-width: 1024px) {
    width: 200px;
    height: 50px;
    font-size: 15px;
  }

  @media (min-width: 1440px) {
    width: 250px;
    height: 60px;
    font-size: 20px;
  }
`;

export default Button;