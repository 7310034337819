import React, 
    {
        //useEffect, 
        //useState
    }
from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import success from '../../../assets/alert-success.svg';
import error from '../../../assets/alert-error.svg';
import fail from '../../../assets/alert-fail.svg';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #0000004b;
    position: fixed;
    top: 0;
    bottom: 0; 
    left: 0; 
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const Frame = styled.div((props: any) =>`
    width: 330px;
    height: 390px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1001;
    border: 1px solid ${props.borderColor};
`);

interface Props {
    setShow: (e: any) => void,
    title: string,
    image: string,
    backUrl?: any,
    borderColor?: string,
}

const Alert = (props: Props) => {

    const history = useNavigate();
    //const [timeoutId, setTimeoutId] = useState<any>();

    // useEffect(() => {
    //     if(props.image === 'success') {
    //         setTimeoutId(setTimeout(() => {
    //             props.setShow(false);
    //             props.backUrl && history(props.backUrl);
    //         }, 2000));
    //     }
    // //eslint-disable-next-line
    // }, [])

    // useEffect(() => {
    //     if(props.image === 'success') {
    //         setTimeout(() => {
    //             props.setShow(false);
    //             props.backUrl && history(props.backUrl);
    //         }, 2000)
    //     }
    // //eslint-disable-next-line
    // }, [])

    return (
        <Container onClick={() => {props.setShow(false); props.backUrl && history(props.backUrl);}}>
            {/*@ts-ignore*/}
            <Frame borderColor={props.borderColor}>
                <img src={props.image === 'success' ? success : (props.image === 'fail' ? fail : error)} style={{width: '120px', height: '120px'}} alt='logo'/>
                <p style={{fontSize: '20px', fontWeight: '600', marginTop: '30px', textAlign: 'center', maxWidth: '100%', padding: '10px'}}>{props.title}</p>
                <button onClick={() => {props.setShow(false);}} style={{width: '40px', height: '30px', backgroundColor: `${props.borderColor}`, border: 'none', color: 'white', borderRadius: '5px'}}>OK</button>
            </Frame>
        </Container>
    )
}

export default Alert;