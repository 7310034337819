import React, { useState, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components';
import ProfilePic from '../../Components/ProfilePic';
import leftArrow from '../../../assets/left-arrow.svg';
import { useNavigate } from 'react-router-dom';
//import calendar from '../../../assets/calendar-icon.svg';
import { instance } from '../../../Providers/api';
import { useDispatch, useSelector } from 'react-redux';
import { SET_HELPERS } from '../../Redux/constants';
import leftArrowDate from '../../../assets/left-arrow-date.svg';

const Container = styled.div`
    width: 100vw;
    //height: 100vh;
    background-color: #eeeeee;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    color: #727070;
`;

const Section = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #e7e7e7;
    position: relative;
`;

const TopBar = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 15px;
`;

const Navigation = styled.div`
    display: flex;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
`;

const Title = styled.span`
    font-size: 20px;
    margin-left: 15px;
    font-weight: 800;
`;

const BodyContainer = styled.div((props: any) => `
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
    display: flex;
    padding-top: 20px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 125px);
`);

const DateContainer = styled.div`
    height: 130px;
    color: #727070;
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 80%;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Button = styled.button((props: any) => `
    margin-top: 20px;
    background-color: ${props.isDisabled ? localStorage.getItem('color') + 'a6' : localStorage.getItem('color')};
    color: #fff;
    width: 260px;
    height: 50px;
    font-size: 16px;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
`);

const Fecha = () => {

    const logo = localStorage.getItem('logo');
    const history = useNavigate();
    const [dateList, setDateList] = useState<any>([]);
    const [stylists, setStylists] = useState<any>();
    const [stylistSelected, setStylistSelected] = useState<any>();
    const helpers = useSelector((state: any) => state.helpers);
    const [day, setDay] = useState<any>({});
    const [hours, setHours] = useState<any>([]);
    const [selectedHour, setSelectedHour] = useState<any>('');
    const dispatch = useDispatch();
    const now = new Date().toLocaleTimeString();
    const today = new Date();
    const [intervalsLeft, setIntervalsLeft] = useState<any>([]);
    const [intervalsRight, setIntervalsRight] = useState<any>([]);
    const [intervalIdLeft, setIntervalIdLeft] = useState<any>();
    const [intervalIdRight, setIntervalIdRight] = useState<any>();

    const toDayName = (n: number) => {
        switch(n) {
            case 0: return 'Domingo';
            case 1: return 'Lunes';
            case 2: return 'Martes';
            case 3: return 'Miercoles';
            case 4: return 'Jueves';
            case 5: return 'Viernes';
            case 6: return 'Sabado';
        }
    }

    const toMonthName = (n: number) => {
        switch(n) {
            case 1: return 'ENE';
            case 2: return 'FEB';
            case 3: return 'MAR';
            case 4: return 'ABR';
            case 5: return 'MAY';
            case 6: return 'JUN';
            case 7: return 'JUL';
            case 8: return 'AGO';
            case 9: return 'SEP';
            case 10: return 'OCT';
            case 11: return 'NOV';
            case 12: return 'DIC';
        }
    }

    const generateDateList = () => {
        var date = new Date();
        var newDateList = [];
        for(var i = 0; i < 90; i++) {
            newDateList.push({numero: (date.getDate()), dia: toDayName(date.getDay()), mes: date.getMonth() + 1, año: date.getFullYear()});
            date.setDate(date.getDate() + 1);
        }
        return newDateList;
    }

    const autoScroll = (direction: string) => {
        if(direction === 'left') {
            setIntervalIdLeft(setInterval(() => {document.getElementById('scroll')?.scrollBy(-3, 0);}, 5));
            setIntervalsLeft([...intervalsLeft, intervalIdLeft]);
        }
        else { 
            setIntervalIdRight(setInterval(() => {document.getElementById('scroll')?.scrollBy(3, 0);}, 5));
            setIntervalsRight([...intervalsRight, intervalIdRight]);
        }
    }

    const toComparableNumber = (s: string) => {
        var a = s.split(':')
        return parseFloat(a[0]) + parseFloat(a[1]) / 60
    }

    useLayoutEffect(() => {
        instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
        const newDateList = generateDateList();
        setDateList(newDateList);
        const enabledDates = newDateList.filter((d: any) => !helpers.locationSelected.fechas_no_atencion.includes(`${d.año}-${('0' + d.mes).slice(-2)}-${('0' + d.numero).slice(-2)}`));
        setDay(helpers.day || enabledDates[0]);
        instance.post('api/v1/Estilistas/listarestilistasporservicio' , {
            idempresa: helpers.companyId,
            idlocal: helpers.locationSelected.id_sede,
            idservicio: helpers.serviceChosen.idservicio,
        })
        .then((res: any) => {
            setStylists([{idEmpleado: -1, nombreEmpleado: 'Cualquier miembro del equipo'}, ...res.data.data]);
            setStylistSelected(helpers.stylistSelected || {idEmpleado: -1, nombreEmpleado: 'Cualquier miembro del equipo'});})
        .then(() => {
            if(helpers.serviceChosen?.idPromocion) {
                if(day?.año) {
                    return instance.get(`api/v1/Promociones/listarHorariosDisponibles?idPromocion=${helpers.serviceChosen.idPromocion}&fecha=${day?.año}-${('0' + (day?.mes)).slice(-2)}-${('0' + day?.numero).slice(-2)}&idLocal=${helpers.locationSelected.id_sede}`)}
                else return;
            }
            else {
                if(day?.año) {
                    return instance.post('api/v1/Estilistas/horariosdisponibilidad/estilista', {
                        id_empresa: helpers.companyId,
                        id_sede: helpers.locationSelected.id_sede,
                        id_servicio: helpers.serviceChosen.idservicio,
                        fecha: `${day?.año}-${('0' + (day?.mes)).slice(-2)}-${('0' + day?.numero).slice(-2)}`,
                        id_empleado: stylistSelected?.idEmpleado,
                    })
                }
                else return;
            }
        })
        .then((res: any) => {
            if(stylistSelected?.idEmpleado !== -1 && day?.año) {
                setHours(res.data.data.horarios);
            }
        });
    //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(helpers.serviceChosen?.idPromocion) {
            if(day?.año && stylistSelected?.idEmpleado) {
                if(day?.año) {
                    instance.get(`api/v1/Promociones/listarHorariosDisponibles?idPromocion=${helpers.serviceChosen.idPromocion}&fecha=${day?.año}-${('0' + (day?.mes)).slice(-2)}-${('0' + day?.numero).slice(-2)}&idLocal=${helpers.locationSelected.id_sede}`)
                    .then((res: any) => setHours(res.data.data));
                }
            }
        }
        else {
            if(day?.año && stylistSelected?.idEmpleado) {
                instance.post('api/v1/Estilistas/horariosdisponibilidad/estilista', {
                    id_empresa: helpers.companyId,
                    id_sede: helpers.locationSelected.id_sede,
                    id_servicio: helpers.serviceChosen.idservicio,
                    fecha: `${day?.año}-${('0' + (day?.mes)).slice(-2)}-${('0' + day?.numero).slice(-2)}`,
                    id_empleado: stylistSelected?.idEmpleado,
                })
                .then((res: any) => setHours(res.data.data));
            }
        }
    //eslint-disable-next-line
    }, [day, stylistSelected]);

    useEffect(() => {
        setSelectedHour(helpers.hour || '');
    //eslint-disable-next-line
    }, [stylistSelected, day]);

    const clearRunningIntervals = () => {
        intervalsLeft.map((i: any) => {clearInterval(i); return null;});
        intervalsRight.map((i: any) => {clearInterval(i); return null;});
    }

    return (
        <Container>
            <Section>
                <TopBar>
                    <img src={logo?.toString() || ''} alt='logo' style={{height: '30px', marginLeft: '40px'}} onClick={() => {history(`/${helpers.slug}`)}}/>
                    <div style={{position: 'absolute', right: '20px'}}><ProfilePic/></div>
                </TopBar>
                <Navigation>
                    <img src={leftArrow} style={{width: '10px', marginLeft: '15px'}} alt='back' onClick={() => helpers.serviceChosen.idservicio === -1 ? history('/reserva/sedes') : history('/reserva/servicios')}/>
                    {/* <img src={calendar} style={{width: '25px', marginLeft: '15px'}} alt='ubicacion'/> */}
                    <Title>
                        Reservas
                    </Title>
                </Navigation>
                <BodyContainer>
                    <div style={{width: '85%'}}>
                        {!helpers.serviceChosen.idPromocion && helpers.serviceChosen.idservicio !== -1 && helpers.locationSelected.seleccionEstilista
                        ? <div>
                            <p style={{fontSize: '18px', fontWeight: '700'}}>Selecciona quién te atenderá</p>
                            <select style={{width: '100%', height: '32px', borderRadius: '5px'}} value={stylistSelected?.idEmpleado.toString()} 
                                onChange={(e: any) => {setStylistSelected(stylists.filter((s: any) => s.idEmpleado.toString() === e.target.value)[0]);}}
                            >
                                {stylists?.map((s: any, i: any) => <option key={i} value={s.idEmpleado}>{s.nombreEmpleado}</option>)}
                            </select>
                        </div>
                        : null}
                        <p style={{fontSize: '18px', fontWeight: '700'}}>Selecciona la fecha y el horario</p>
                        <p style={{fontSize: '18px', fontWeight: '700'}}>Fecha</p>
                    </div>
                    <div style={{width: '100%', backgroundColor: '#e7e7e7', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                        {window.innerWidth > 600 ? <img src={leftArrowDate} alt='left' style={{position: 'absolute', left: '15px', height: '15px', cursor: 'pointer'}} id='scroll-left'
                            onMouseDown={() => autoScroll('left')} onMouseUp={() => {clearInterval(intervalIdLeft); clearRunningIntervals();}} onDrag={() => {clearInterval(intervalIdLeft);}}/> : null}
                        <DateContainer id='scroll'>
                            {dateList.map((d: any, i: any) => 
                                <div key={i} 
                                    style={{
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'center', 
                                        alignItems: 'center',
                                        fontWeight: '800',
                                    }}
                                >
                                    
                                        <span
                                            style={{
                                                fontSize: '13px',
                                                lineHeight: '40px',
                                                color: helpers.locationSelected.fechas_no_atencion.includes(`${d.año}-${('0' + d.mes).slice(-2)}-${('0' + d.numero).slice(-2)}`) ? '#9e9e9e' : 'inherit'
                                            }}
                                        >
                                            {d.dia[0]}
                                        </span>
                                    <div style={{width: '40px', margin: '0', padding: '0', backgroundColor: 'transparent', height: '0', border: '1px solid ' + (helpers.locationSelected.fechas_no_atencion.includes(`${d.año}-${('0' + d.mes).slice(-2)}-${('0' + d.numero).slice(-2)}`) ? '#9e9e9e' : '#727070')}}/>
                                    <div onClick={() => {
                                        if(!helpers.locationSelected.fechas_no_atencion.includes(`${d.año}-${('0' + d.mes).slice(-2)}-${('0' + d.numero).slice(-2)}`)) setDay(d)}}
                                        style={{
                                            borderRadius: '50%',
                                            backgroundColor: day.numero === d.numero && day.mes === d.mes && day.año === d.año ? '#424242' : 'transparent',
                                            width: '30px',
                                            height: '30px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            margin: '9px 0 4px'
                                        }}
                                    >
                                        <span 
                                            style={{
                                                color: helpers.locationSelected.fechas_no_atencion.includes(`${d.año}-${('0' + d.mes).slice(-2)}-${('0' + d.numero).slice(-2)}`) ? '#9e9e9e' : (day.numero === d.numero && day.mes === d.mes && day.año === d.año ? '#ffffff' : '#727070'),
                                                fontSize: '13px', 
                                                lineHeight: '40px',
                                                }}
                                        >
                                            {('0' + d.numero).slice(-2)}
                                        </span>
                                    </div>
                                    <span style={{fontSize: '13px', fontWeight: day.numero === d.numero && day.mes === d.mes && day.año === d.año ? '800' : '400',
                                        visibility: d.numero === 1 || i === 0 || (day.numero === d.numero && day.mes === d.mes && day.año === d.año)
                                            ? 'visible' : 'hidden'}}
                                    >{toMonthName(d.mes)}</span>
                                </div>
                            )}
                        </DateContainer>
                        {window.innerWidth > 600 ? <img src={leftArrowDate} alt='right' style={{position: 'absolute', right: '15px', height: '15px', transform: 'rotate(180deg)', cursor: 'pointer'}}
                            onMouseDown={() => autoScroll('right')} onMouseUp={() => {clearInterval(intervalIdRight); clearRunningIntervals();}} onDrag={() => {clearInterval(intervalIdRight);}}/> : null}
                    </div>
                    <div style={{width: '85%'}}>
                        <div>
                            <p style={{fontSize: '18px', fontWeight: '700'}}>Horario</p>
                            {hours?.filter((h: any) =>
                                toComparableNumber(h) >= toComparableNumber(helpers.locationSelected.hora_apertura)
                                && ((today.getDate() === day.numero && today.getMonth() === (day.mes - 1) && today.getFullYear() === day.año) ? toComparableNumber(h) >= toComparableNumber(now) + 1.5 : true)
                                && toComparableNumber(h) <= toComparableNumber(helpers.locationSelected.hora_cierre) - 1).length > 0
                            ? <>
                            <p>Mañana</p>
                            <div 
                                style={{
                                    display: 'flex', 
                                    flexWrap: 'wrap',
                                }}
                            >
                                {hours?.filter((h: any) => 
                                    toComparableNumber(h) < 12 
                                    && toComparableNumber(h) >= toComparableNumber(helpers.locationSelected.hora_apertura)
                                    && ((today.getDate() === day.numero && today.getMonth() === (day.mes - 1) && today.getFullYear() === day.año) ? toComparableNumber(h) >= toComparableNumber(now) + 1.5 : true)).length > 0
                                ? hours?.filter((h: any) => 
                                    toComparableNumber(h) < 12 
                                    && toComparableNumber(h) >= toComparableNumber(helpers.locationSelected.hora_apertura)
                                    && ((today.getDate() === day.numero && today.getMonth() === (day.mes - 1) && today.getFullYear() === day.año) ? toComparableNumber(h) >= toComparableNumber(now) + 1.5 : true)).map((h: any, i: any) => 
                                    <div key={i} onClick={() => setSelectedHour(h)}
                                        style={{
                                            border: '1px solid #727070',
                                            padding: '10px',
                                            borderRadius: '5px',
                                            margin: '0 10px 10px 0',
                                            backgroundColor: h === selectedHour ? '#727070' : 'transparent',
                                            color: h === selectedHour ? '#ffffff' : '#727070',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {h}
                                    </div>
                                )
                                : <p style={{width: '100%', textAlign: 'center', fontSize: '14px'}}>No existen horarios de mañana<br/> 
                                    en la fecha seleccionada</p>}
                            </div>
                            <p>Tarde</p>
                            <div 
                                style={{
                                    display: 'flex', 
                                    flexWrap: 'wrap',
                                }}
                            >
                                {hours?.filter((h: any) => 
                                    toComparableNumber(h) > 11.5
                                    && ((today.getDate() === day.numero && today.getMonth() === (day.mes - 1) && today.getFullYear() === day.año) ? toComparableNumber(h) >= toComparableNumber(now) + 1.5 : true)
                                    && toComparableNumber(h) <= toComparableNumber(helpers.locationSelected.hora_cierre) - 1).length > 0
                                ? hours?.filter((h: any) => 
                                    toComparableNumber(h) > 11.5
                                    && ((today.getDate() === day.numero && today.getMonth() === (day.mes - 1) && today.getFullYear() === day.año) ? toComparableNumber(h) >= toComparableNumber(now) + 1.5 : true)
                                    && toComparableNumber(h) <= toComparableNumber(helpers.locationSelected.hora_cierre) - 1).map((h: any, i: any) => 
                                    <div key={i} onClick={() => setSelectedHour(h)}
                                        style={{
                                            border: '1px solid #727070',
                                            padding: '10px',
                                            borderRadius: '5px',
                                            margin: '0 10px 10px 0',
                                            backgroundColor: h === selectedHour ? '#727070' : 'transparent',
                                            color: h === selectedHour ? '#ffffff' : '#727070',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {h}
                                    </div>
                                )
                                : <p style={{width: '100%', textAlign: 'center', fontSize: '14px'}}>No existen horarios de tarde<br/> 
                                    en la fecha seleccionada</p>}
                            </div>
                            </>
                            : 
                             <p style={{width: '100%', textAlign: 'center', fontSize: '14px'}}>No existen horarios en la fecha seleccionada.<br/> 
                              Por favor,<br/> seleccione otro estilista u otra fecha.</p>
                            }
                        </div>
                    </div>
                    <Button
                        //@ts-ignore
                        isDisabled={!selectedHour}
                        disabled={!selectedHour}
                        onClick={() => {dispatch({type: SET_HELPERS, payload:{day, hour: selectedHour, stylistSelected}}); history('/reserva/resumen');}}
                    >
                        Elegir
                    </Button>
                </BodyContainer>
            </Section>
        </Container>
    )
}

export default Fecha;