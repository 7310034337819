import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { instance } from '../../../Providers/api';
import { SET_USER, SET_HELPERS } from '../../Redux/constants';
import Alert from '../../Components/Alert';
import jwtDecode from 'jwt-decode';
import styled from 'styled-components';
import BackNavButton from '../../../Components/elements/Navigation/backNavButton';
import visibility from '../../../assets/visibility-on-icon.svg';
import visibilityOff from '../../../assets/visibility-off-icon.svg';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
`;

const Section = styled.div`
    width: 100%;
    max-width: 396px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin: auto;
    
    .recover-password{
        display: flex;
        width: 300px;
        gap: 5px; 
    }
    @media (max-width: 1439px) {
        max-width: 328px;
    }

    input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #77767A;
  font-style: italic;
  -webkit-box-shadow: 0 0 0px 1000px #fdfdfd inset;
}
`;


const Button = styled.button((props: any) => `
    font-family: 'mulish';
    cursor: pointer;
    color: white;
    background-color: ${localStorage.getItem('color')};
    height: 40px; 
    width: 100%; 
    outline: none; 
    border: none;
    border-radius: 5px;
    font-size: 14px;
`);

const Login = () => {

    const dispatch = useDispatch();
    const history = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState(false);
    const [fail, setFail] = useState(false);
    const [logo, setLogo] = useState<any>();
    const helpers = useSelector((state: any) => state.helpers);

    const googleCallback = async(res: any) => {
        const info: any = jwtDecode(res.credential);
        localStorage.setItem('image', info.picture);
        try{
            await instance.post('api/v1/LoginUsuario/google', {
                googleToken: res.credential,
            }).then(response =>
            {dispatch({
                type: SET_HELPERS,
                payload: {
                    bearer: response.data.data,
                }
            });
            instance.defaults.headers.common.Authorization = `Bearer ${response.data.data}`;})
            .then(() => instance.get(
                //'/datausuario'
                'datausuario'
                ))
            .then(res => {
                dispatch({
                type: SET_USER,
                payload: { ...res.data.data }});
            })
            .then(() => {setAlert(true);});
        }
        catch(e: any) {
            setFail(true);
            console.log('error', e.message);
        }
    }

    useEffect(() => {
        if(helpers.user.nombre) history("/reserva/sedes");
        //@ts-ignore
        google.accounts.id.initialize({
            client_id: '306825456204-r45v66rombplhlsktgejqof2o7h8lcam.apps.googleusercontent.com',
            callback: googleCallback,
        });

        //@ts-ignore
        google.accounts.id.renderButton(
            document.getElementById('loginButtonDiv'),
            {theme: 'outline', size: 'large', width:`${window.innerWidth<1440?'328px':'396px'}`}
        );
        setLogo(localStorage.getItem('logo'));
    //eslint-disable-next-line
    }, []);

    useLayoutEffect(() => {
        instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
    //eslint-disable-next-line
    }, []);

    const handleLogin = async() => {
        try{
            await instance.post('api/v1/LoginUsuario/correo', {
                correo: email,
                contrasena: password
            })
            .then(response =>
            {
            dispatch({
                type: SET_HELPERS,
                payload: {
                    bearer: response.data.data,
                }
            });
            instance.defaults.headers.common.Authorization = `Bearer ${response.data.data}`;})
            .then(() => instance.get(
                //'/datausuario'
                'datausuario'
                ))
            .then(res => {
                dispatch({
                type: SET_USER,
                payload: { ...res.data.data }});
                localStorage.setItem('image', '');
            })
            .then(() => {setAlert(true);})
            .catch((e: any) => {setFail(true); console.log('error', e.message);});
        }
        catch(e: any) {
            setFail(true);
            console.log('error', e.message);
        }
    }

    const [showPassword, setShowPassword] = useState(false);
    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
      };

    return (
        <>
        {alert ? <Alert setShow={setAlert} title='¡Sesión iniciada con éxito!' image={'success'} backUrl={-1} borderColor={'#0DCA64'}/> : null}
        {fail ? <Alert setShow={setFail} title='Ocurrió un error al iniciar sesión. Comprueba tu correo y contraseña.' image={'fail'} borderColor={'#CA450D'}/> : null}
        <div style={{ backgroundColor: '#ffffff'}}>
            <Container>
                <div style={{paddingTop:'28px',paddingLeft:'36px'}}>
                <BackNavButton text='Iniciar Sesión' url={""}/>
                </div>
                
                
                <Section>
                
                    <div style={{display:'flex',justifyContent:'center'}}> 
                    {logo?(<img src={logo || ''} alt='logo' referrerPolicy="no-referrer"
                            style={{
                                objectFit: 'contain',
                                maxHeight: '200px',
                                maxWidth: '300px',
                                marginBottom: '32px',
                            }}
                    />):null}
                    </div>
                        <InputEmail 
                        type='text' 
                        value={email} 
                        onChange={e => setEmail(e.target.value)}
                        placeholder='Correo electrónico'
                        
                        />
                        
                        <InputPassword>
                        <input
                        value={password} 
                        onChange={e => setPassword(e.target.value)}
                        type={showPassword ? "text" : 'password'}
                        placeholder='Escribe tu contraseña'
                        
                        />
                        <button onClick={()=>handlePasswordToggle()}>
                            {showPassword ?<img src={visibility} alt="visibility" />:<img src={visibilityOff} alt="visibilityOff" />}
                        </button>
                        </InputPassword>
                        <div className='recover-password'>
                        <p style={{fontSize: '12px', color: '#9e9e9e',display:'inline'}} 
                        >¿Olvidaste tu contraseña?</p>
                        <p onClick={() => {history('/recuperar-contrasena')}} style={{fontWeight: 600,fontSize: '12px', cursor: 'pointer', color: `${localStorage.getItem('color')}`,display:'inline'}} > Da clic aquí</p>
                        </div>
                        
                        <Button onClick={() => handleLogin()}>
                            Iniciar sesión
                        </Button>
                        <div style={{width: '100%', marginTop: '16px',height:'40px'}}>                       
                        {window.innerWidth>0?<div id='loginButtonDiv'></div>:null}
                        </div>
                        <p style={{fontSize: '12px', textAlign: 'center'}}>¿Aún no tienes cuenta? <span style={{fontWeight: '800', cursor: 'pointer'}} onClick={() => {history('/registro')}}>Regístrate aquí</span></p>
                </Section>
            </Container>
            
        </div>
        </>
    )
}

export default Login;


const InputEmail= styled.input`
font-family: 'Mulish';
height: 48px;
width: 100%; 
border-radius: 4px;
margin-bottom: 16px;
border: 1px solid #77767A;
padding: 10px;
box-sizing: border-box;
outline: none;


::placeholder{
    color: #77767A;
    size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
}

`
const InputPassword = styled.div`
font-family: 'Mulish';
border-radius: 4px;
border: 1px solid #77767A;
height: 48px;
width: 100%; 
display: flex;
justify-content: space-between;
padding: 10px;
box-sizing: border-box;


input{
    font-family: 'Mulish';
    background: none;
    outline: none;
    width: 100%;
    border: none;

    ::placeholder{
    color: #77767A;
    size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Mulish';
    
    
}

}
button{
    border: none;
    outline: none;
    display: flex;

    background: none;
    img{
        margin: 0;
        align-self: center;
         
    }
}

`