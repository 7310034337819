import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { instance } from '../../../Providers/api';
import flecha from '../../../assets/left-arrow.svg';
import Alert from '../../Components/Alert';
import styled from 'styled-components';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #eeeeee;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #E5E5E5;
`;

const Section = styled.div`
    background-color: white;
    width: 100vw;
    max-width: 600px;
    margin-top: 43px;
    border-radius: 20px 20px 0 0;
    font-size: 18px;
    display: flex;
    justify-content: center;
`;

const Input = styled.input`
    margin: 0;
    height: 32px; 
    width: 100%;
    max-width: 278px; 
    margin-top: 5px; 
    border-radius: 5px; 
    border: 1px solid #727070; 
    outline: none;
    padding: 0 10px;
`;

const Button = styled.button`
    color: white;
    background-color: ${localStorage.getItem('color')}; 
    height: 40px; 
    width: 300px; 
    outline: none; 
    border: none;
    border-radius: 5px;
    margin-top: 35px;
    font-size: 14px;
`;

const CambiarContraseña = () => {

    const history = useNavigate();
    const [contrasena, setContrasena] = useState('');
    const [confirmar, setConfirmar] = useState('');
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const helpers = useSelector((state: any) => state.helpers);
    const logo = localStorage.getItem('logo');
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
        if(!token) history(`/${helpers.slug}`);
    //eslint-disable-next-line
    }, []);

    const handleSubmit = () => {
        if(contrasena !== confirmar) {setFail(true); return;}
        instance.post('/RecuperarContraseña/cambiarcontraseña', {
            token: token,
            nuevaContraseña: contrasena,
        })
        .then(() => setSuccess(true))
        .catch(() => setFail(true));
    }

    return (
        <>
        {success ? <Alert setShow={setSuccess} title='Contraseña actualizada correctamente' image={'success'} backUrl={`/${helpers.slug}`} borderColor={'#0DCA64'}/> : null}
        {fail ? <Alert setShow={setFail} title='Hubo un error al actualizar su contraseña' image={'fail'} backUrl={`/${helpers.slug}`} borderColor={'#CA450D'}/> : null}
        <div style={{display: 'flex', justifyContent: 'center', color: '#727070'}}>
            <Container>
                <img src={flecha} alt='back' style={{marginTop: '10px', marginLeft: '10px', alignSelf: 'flex-start'}} onClick={() => {history(-1)}}/>
                <p style={{marginTop: '0px'}}>¡Hola!</p>
                <img src={logo || ''} alt='logo' style={{height: '97px', marginTop: '0px'}}/>
                <p style={{fontWeight: '800', marginTop: '20px'}}>Reservas</p>
                <Section>
                    <div style={{width: '80vw', maxWidth: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{fontWeight: '800', marginTop: '30px', textAlign: 'left', width: '80vw', maxWidth: '300px'}}>Cambiar contraseña</p>
                        <p style={{marginTop: '20px', marginBottom: '0', textAlign: 'left', width: '80vw', maxWidth: '300px'}}>Nueva contraseña</p>
                        <Input type='text' value={contrasena} onChange={e => setContrasena(e.target.value)}/>
                        <p style={{marginTop: '20px', marginBottom: '0', textAlign: 'left', width: '80vw', maxWidth: '300px'}}>Confirmar contraseña</p>
                        <Input type='text' value={confirmar} onChange={e => setConfirmar(e.target.value)}/>
                        <Button onClick={() => handleSubmit()}>
                            Confirmar
                        </Button>
                    </div>
                </Section>
            </Container>
        </div>
        </>
    )
}

export default CambiarContraseña;