import React, { useState } from "react";
import styled from "styled-components";

interface MyComponentProps {
  color: string;
  data: any;
  seleccionarServicio: any;
  isSelected: boolean;
}

const Service: React.FC<MyComponentProps> = ({
  color,
  data,
  seleccionarServicio,
  isSelected,
}) => {
  const capitalize = (s: string) => {
    const palabras = s.split(' ');

    const resultado = palabras.map((palabra) => {
      const primeraLetra = palabra.charAt(0).toUpperCase();
      const restoPalabra = palabra.slice(1).toLowerCase();
      return primeraLetra + restoPalabra;
    });
  
    return resultado.join(' ');
  };

  const [openDescription, setOpenDescription] = useState<boolean>(false);

  const switchDescription = () => {
    setOpenDescription(!openDescription);
  };

  return (
    <Container>
      <ServiceStyle isSelected={isSelected} color={color} isOpen={openDescription} onClick={() =>{
        if(window.innerWidth>=1440)seleccionarServicio(data)
      }
      }>
        <div className='title'>
          <div>
            <p>{data.nombre ? capitalize(data.nombre) : "-"}</p>
            <div className='promo'>
              {data.idPromocion ? <span> PROMO </span> : null}
            </div>
          </div>
          <p>
            {`S/ ${
              data?.precio?.toString().includes(".")
                ? data.precio
                : data.precio + ".00"
            }`}
          </p>
        </div>
        <div className='info'>
          <>
            {data.serviciosPromocion?.map((e: any) => (
              <p>{e.nombreServicio}</p>
            ))}
            <p>{data.terminos ? data.terminos : ""}</p>
          </>
        </div>
        <div className='buttons'>
          {data.idPromocion ? (
            <button className='masInfo' onClick={() => switchDescription()}>
              Más info ^
            </button>
          ) : (
            <div></div>
          )}
          {!(window.innerWidth>=1440)? <button
            className='reservar'
            onClick={() => seleccionarServicio(data)}>
            Reservar
          </button>:<div></div>}
        </div>
      </ServiceStyle>
    </Container>
  );
};

export default Service;

const Container = styled.div<{}>`
`;

const ServiceStyle = styled.div<{ color: string; isOpen: boolean,isSelected:boolean }>`
  //hover
  &:hover {
    ${window.innerWidth>=1440?"background: #EFEFEF":""};
  }
  min-height: 86px;
  border-radius: 4px;
  border: ${props=> props.isSelected && window.innerWidth>=1440?` 2px solid ${props.color}` :'1px solid #77767a'};
  padding: 12px 20px;
  margin-bottom: 16px;
  display: grid;
  .info {
    height: 100%;
    display: ${(props) => (props.isOpen ? "grid" : "none")};
    padding: 8px;
    p {
      font-size: 12px;
      padding: 0;
      margin: 0;
    }
  }
  .buttons {
    height: 100%;
    display: flex;
    justify-content: space-between;
    .masInfo {
      align-self: flex-end;
      cursor: pointer;
      color: ${(props) => props.color};
      border: none;
      outline: none;
      background: none;
      text-decoration-line: underline;
      padding: 0;
      margin: 0;
      font-size: 12px;
      font-weight: 700;
      font-style: italic;
    }
    .reservar {
      cursor: pointer;
      border: none;
      outline: none;
      background-color: ${(props) => props.color};
      color: white;
      width: 83px;
      height: 32px;
      border-radius: 8px;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    .promo {
      height: 30px;
      span {
        padding: 2px 8px;
        border-radius: 24px;
        border: 1px solid ${(props) => props.color};
        font-weight: 400;
        font-style: italic;
        color: ${(props) => props.color};
        font-size: 11px;
      }
    }
    p {
      font-family: "Mulish", sans-serif;
      font-weight: 600;
      font-size: 14px;
      padding: 0px;
      margin: 0px;
    }
  }
`;
