import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { instance } from "../../../Providers/api";
import { SET_FORM, SET_HELPERS } from '../../../State/redux/constants/helpers';
import jwtDecode from 'jwt-decode';

// interface Props {
//     setShow: (e: any) => void,
//     setAlert: (e: any) => void,
//     setFail: (e: any) => void,
// }
interface MyComponentProps {
    width: number;
  }

const ButtonGoogleLogin : React.FC<MyComponentProps> = ({width}) => {

  const dispatch = useDispatch();

  const googleCallback = async(res: any) => {
      const info: any = jwtDecode(res.credential);
      localStorage.setItem('image', info.picture);
      
          await instance.post('api/v1/LoginUsuario/google', {
              googleToken: res.credential,
          }).then(response =>
          {dispatch({
              type: SET_HELPERS,
              payload: {
                  bearer: response.data.data,
              }
          });
          instance.defaults.headers.common.Authorization = `Bearer ${response.data.data}`;})
          .then(() => instance.get('datausuario'))
          .then(res => { 
              dispatch({
              type: SET_FORM,
              payload: {
                  usuario: {
                      ...res.data.data
                  }
              }});
          })
          .then(() => {console.log('success')});
      
  }

  useEffect(() => {
    if(width>0){
      //@ts-ignore
      google.accounts.id.initialize({
          client_id: '306825456204-r45v66rombplhlsktgejqof2o7h8lcam.apps.googleusercontent.com',
          callback: googleCallback,
      });

      //@ts-ignore
      google.accounts.id.renderButton(
          document.getElementById('loginButtonDiv'),
          { size: 'large', width: `${width}px`}
      );
    }
      //eslint-disable-next-line
  },[width])

  

  //box.current.style.backgroundColor = "blue";

    return (
      <div style={{height:'40px'}} >
      <div  id='loginButtonDiv' ></div>
  </div>
      )
}

export default ButtonGoogleLogin;



