import React, { useState, useEffect } from "react";
import "./Landing.css";
import logoVertical from "../../../assets/landing/LogoVertical.svg";
import logoViutifyHorizontal from "../../../assets/landing/LogoViutifyHorizontal.svg";
import imagenPrincipal from "../../../assets/landing/ImgPrincipal.png";
import logoSoho from "../../../assets/landing/LogoSoho.svg";
import logoBE from "../../../assets/landing/LogoBE.svg";
import logoKaoba from "../../../assets/landing/LogoKaoba.svg";
import logoMotivosSpa from "../../../assets/landing/LogoMotivosSpa.svg";
import logoCarppone from "../../../assets/landing/LogoCarppone.svg";
import logoTNB from "../../../assets/landing/TheNailsBar.svg";
import iconoLink from "../../../assets/landing/iconoLink.svg";
import iconoBillete from "../../../assets/landing/IconoBillete.svg";
import iconoServicios from "../../../assets/landing/iconoServicios.svg";
import iconoIntegracion from "../../../assets/landing/IconoIntegracion.svg";
import imagenPantallas from "../../../assets/landing/ImagenPantallas.png";
import imagenChica from "../../../assets/landing/ImgenChicaExplicando.png";
import imagenMano from "../../../assets/landing/imagenCelularManoRobot.png";
import iconoQuote from "../../../assets/landing/iconoQuote.svg";
import iconoWhatsapp from "../../../assets/landing/iconWhatsapp.svg";
import iconoFacebook from "../../../assets/landing/iconFacebook.svg";
import iconoInstagram from "../../../assets/landing/iconInstagram.svg";
import imagenLaptop from "../../../assets/landing/imagenLaptop.png";
import imagenPrincipalDesktop from "../../../assets/landing/ImagenPrincipalDesktop.png";
import imagenPantallasDesktop from "../../../assets/landing/imagenPantallasDesktop.png";
import imagenLaptopDesktop from "../../../assets/landing/imagenLaptopDesktop.png";
import Button from './Components/Button';

const WHATSAPP_LINK = "https://api.whatsapp.com/send/?phone=51936716361&text=%C2%A1Hola!+Me+interesa+el+sistema+de+reservas+de+viutify";
const FACEBOOK_LINK = "https://www.facebook.com/viutify";
const INSTAGRAM_LINK = "https://www.instagram.com/viutify_/";

const LandingPage = () => {
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setShowButton(false);
      } else {
        setShowButton(true);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleContactUs = () => {
    window.open(WHATSAPP_LINK, '_blank');
  }

  return (
    <>
      <main className="pagina-principal">
        <section className="seccion-principal">
          <div className="contenedor-principal">
            <img src={logoVertical} alt="Logo Viutify Reservas" />
            <h1>Tu propio centro de reservas en línea, listo para usar</h1>
            <p>
              Tus clientes podrán reservar y pagar en línea en menos de 5
              minutos.
            </p>
            <Button onClick={handleContactUs}>Contáctanos</Button>
          </div>
          <div className="contenedor-imagen">
            <img
              className="img-full imagen-principal"
              src={imagenPrincipal}
              alt="Imagen principal de reservas"
            />
            <img
              className="img-full imagen-principal-desktop"
              src={imagenPrincipalDesktop}
              alt="Logo Viutify Reservas"
            />
          </div>
          <div className="contenedor-salones">
            <p>Cada vez más salones de belleza y barberías usan Viutify</p>
            <section className="lista-salones">
              <ul className="">
                <li>
                  <img
                    className="salon-imagen"
                    src={logoSoho}
                    alt="Logo Soho"
                  />
                </li>
                <li>
                  <img className="salon-imagen" src={logoBE} alt="Logo BE" />
                </li>
                <li>
                  <img
                    className="salon-imagen"
                    src={logoMotivosSpa}
                    alt="Logo Motivos Spa"
                  />
                </li>
                <li>
                  <img
                    className="salon-imagen"
                    src={logoKaoba}
                    alt="Logo Kaoba"
                  />
                </li>
                <li>
                  <img
                    className="salon-imagen"
                    src={logoCarppone}
                    alt="Logo Carppone"
                  />
                </li>
                <li>
                  <img className="salon-imagen" src={logoTNB} alt="Logo TNB" />
                </li>
              </ul>
            </section>
          </div>
        </section>

        <section className="seccion-beneficios">
          <div className="contenedor-beneficios">
            <h2>Conoce los beneficios</h2>
            <div className="beneficio-item">
              <span>
                <img
                  className="icon-beneficios"
                  src={iconoLink}
                  alt="Icono mundo"
                />
              </span>
              <p>
                Tus clientes acceden 24/7 a elegir tus servicios a través de tu
                link personalizado
              </p>
            </div>
            <div className="beneficio-item">
              <span>
                <img
                  className="icon-beneficios"
                  src={iconoBillete}
                  alt="Icono billete"
                />
              </span>
              <p>
                Recibe pagos en línea, como adelanto o por el total del servicio
              </p>
            </div>
            <div className="beneficio-item">
              <span>
                <img
                  className="icon-beneficios"
                  src={iconoServicios}
                  alt="Icono servicios"
                />
              </span>
              <p>Tú decides qué servicios ofrecer en línea</p>
            </div>
            <div className="beneficio-item">
              <span>
                <img
                  className="icon-beneficios"
                  src={iconoIntegracion}
                  alt="Icono integración"
                />
              </span>
              <p>
                Integrado a Viutify, el sistema todo en uno que hace crecer
                centros de belleza
              </p>
            </div>
          </div>
          <div className="contenedor-imagen">
            <img
              className="img-full imagen-pantallas"
              src={imagenPantallas}
              alt="Imagen pantallas"
            />
            <img
              className="img-full imagen-pantallas-desktop"
              src={imagenPantallasDesktop}
              alt="Imagen pantallas"
            />
          </div>
        </section>

        <section className="seccion-ayudamos">
          <div className="contenedor-imagen">
            <img className="img-full" src={imagenChica} alt="Imagen chica" />
          </div>
          <div className="contenedor-ayudamos">
            <h2>Te ayudamos a empezar</h2>
            <h3>
              Nuestro equipo te guiará paso a paso, para que empieces sin
              demoras.
            </h3>
            <p>
              Apóyate en nosotros para configurar tus servicios, promociones,
              colaboradores y horarios
            </p>
            {showButton &&  <Button onClick={handleContactUs}>Contáctanos</Button>}
          </div>
        </section>

        <section className="seccion-info">
          <p className="seccion-info__parrafo">
            Más de <span>1000 clientes al día</span> son atendidos a través de
            Viutify
          </p>
          <Button onClick={handleContactUs}>Contáctanos</Button>
        </section>

        <section className="seccion-preguntas">
          <div className="contenedor-preguntas">
            <h2>Preguntas frecuentes</h2>
            <article className="pregunta">
              <h3>¿Cómo sé si me conviene?</h3>
              <p>
                Programa una demostración gratis con nosotros. Te atenderemos y
                absolveremos todas las preguntas que tengas.
              </p>
            </article>
            <article className="pregunta">
              <h3>¿Cúanto cuesta?</h3>
              <p>
                En Viutify tenemos planes desde S/ 69 mensuales, e incluye tu
                sistema de reservas ¡Tenemos promociones especiales!. Ten en
                cuenta que hay recargos por la pasarela de pagos en línea.
                Nosotros no te cobraremos comisiones.
              </p>
            </article>
          </div>
          <div className="contenedor-imagen">
            <img
              className="imagen-mano img-full"
              src={imagenMano}
              alt="Imagen mano con smartphone"
            />
          </div>
        </section>

        <section className="seccion-centros">
          <div className="contenedor-imagen">
            <img
              className="img-full imagen-laptop"
              src={imagenLaptop}
              alt="Imagen laptop"
            />
            <img
              className="img-full imagen-laptop-desktop"
              src={imagenLaptopDesktop}
              alt="Imagen laptop"
            />
          </div>
          <div className="container-centros">
            <p className="container-centros__parrafo">
              Únete a los más de <span>50 centros</span> de belleza que usan
              Viutify todos los días
            </p>
            <Button onClick={handleContactUs}>Contáctanos</Button>
          </div>
        </section>

        <section className="seccion-quote">
          <div className="contenedor-quote">
            <img className="icon-quote" src={iconoQuote} alt="Icono quote" />
            <p>
              ¡Hola! En Viutify entendemos que dedicas una gran cantidad de
              tiempo para atender a tus clientes y programar tus reservas, y
              sabemos que puede ser frustrante cuando a pesar de este esfuerzo,
              algunos no se presentan a sus citas.
            </p>
            <p>
              También sabemos que tus clientes esperan una respuesta inmediata,
              incluso si te escriben fuera de los horarios habituales de
              trabajo.
            </p>
            <p>
              Con Viutify Reservas podrás recibir y confirmar reservas
              automáticamente a cualquier hora del día, sin perder mensajes de
              clientes que te escriban fuera de los horarios habituales de
              trabajo. Esto te permitirá reducir significativamente el tiempo
              que dedicas a la programación de citas.
            </p>
            <p>
              En Viutify queremos ayudarte a hacer que tu trabajo sea más
              fácil y a que tus clientes estén más felices.
            </p>
            <p>Atentamente,</p>
            <p className="contenedor-quote__team">El equipo de Viutify</p>
          </div>
        </section>
      </main>

      <footer className="footer-landing">
        <section className="redes-sociales">
          <div>
            <img
              className="logo-viutify-footer"
              src={logoViutifyHorizontal}
              alt="Logo viutify reservas horizontal"
            />
          </div>
          <div className="iconos-redes">
            <a href={WHATSAPP_LINK} target="_blank" rel="noreferrer">
              <img
                onClick={handleContactUs}
                className="icon-whatsapp"
                src={iconoWhatsapp}
                alt="Logo whatsapp"
              />
            </a>
            <a href={FACEBOOK_LINK} target="_blank" rel="noreferrer">
              <img
                className="icon-facebook"
                src={iconoFacebook}
                alt="Logo facebook"
              />
            </a>
            <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
              <img
                className="icon-instagram"
                src={iconoInstagram}
                alt="Logo instagram"
              />
            </a>
          </div>
        </section>
        <section className="copyright">
          <p>2023 copyright © Viutify</p>
        </section>
      </footer>
    </>
  );
};

export default LandingPage;
