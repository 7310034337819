import React from "react";
import backArrow from "../../../assets/back-arrow-icon.svg";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

interface BackNavButtonProps {
  text: string;
  url: string;
}

const BackNavButton: React.FC<BackNavButtonProps> = ({ text, url }) => {
  const history = useNavigate();
  const navigate = useNavigate();

  const handleClick = () => {
    if (url) {
      navigate(url);
    } else {
      history(-1);
    }
  };

  return (
    <Button onClick={()=>handleClick()}>
      <img src={backArrow} alt="go-back" />
      {text}
    </Button>
  );
};

export default BackNavButton;


const Button = styled.button`
  cursor: pointer;
  background: none;
  font-family: 'Mulish';
  display: flex;
  border: none;
  font-size: 14px;
  align-items: center;
  color: #1B1B1F;
  gap: 10px;
  font-weight: 700;
  padding: 0;

  img{
    padding: 0;
    margin: 0;
  }
  
`;