// @ts-nocheck
import {
    SET_HELPERS,
    SET_USER,
    SET_DATE,
    DELETE_SERVICE,
    SET_RESERVATIONID,
    RESET_STORE,
    RESET_SERVICES,
  } from "../constants";
  
  const initialState = {
    bearer: "",
    company: '',
    companyId: 16,
    stylistSelected: {},
    categoriesSelected: [],
    loading: false,
    cities: [],
    locations: [],
    citySelected: {},
    locationSelected: { id: 0, name: '' },
    services: [],
    promotions: [],
    servicesFiltered: [],
    serviceChosen: {},
    categories: [],
    categoriesId: [],
    message: "Cargando...",
    day: "",
    hour: "",
    reservationId: 0,
    user: {},
    coupon: {},
    couponString: '',
    slug: '',
    idLocation: '',
  };
  
  const initialStateCopy = JSON.parse(JSON.stringify(initialState));
  
  export default function appReducer(state = initialState, { type, payload }) {
    switch (type) {
      case SET_HELPERS:
        return { ...state, ...payload };
      case SET_DATE:
        return {
          ...state,
          day: payload.day, 
          hour: payload.hour,
        };
      case DELETE_SERVICE:
        return {
          ...state,
          serviceChosen: {},
        };
      case SET_RESERVATIONID:
        return {
            ...state,
            reservationId: payload.reservationId,
        };
      case SET_USER:
        return {
          ...state,
          user: {
            ...state.user,
            ...payload
          },
        };
      case RESET_STORE:
        return {
          ...initialStateCopy,
        };
      case RESET_SERVICES:
        return {
          ...state,
          serviceChosen: {},
          servicesFiltered: [],
          promotions: [],
          services: [],
        };
      default:
        return state;
    }
  }