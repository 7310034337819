import React, { useEffect, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import powered from '../../../assets/powered-by-Viuty.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SET_HELPERS } from '../../Redux/constants';
import { instance } from '../../../Providers/api';
import ButtonGoogleLogin from '../../../Components/elements/googleLogin/buttonGoogleLogin';
import LoaderCircle from '../../../Components/elements/Loader/LoaderCircle';


const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
`;
//last-deploy
const Section = styled.div`
    width: 100%;
    max-width: 338px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin: auto;
    .buttons-Container{
        display: flex;
        flex-direction: column;
        
    }

    @media (max-width: 1439px) {
        max-width: 230px;
    }
`;

const Button = styled.button((props: any) => `
    font-family: 'mulish';
    background-color: ${props.color || ''};
    color: #fff;
    width: 100%;
    height: 40px;
    font-size: 14px;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`);

const ButtonLoginCorreo = styled.button((props: any) => `
    font-family: 'mulish';
    background-color:#ffffff;
    color: ${props.color || ''};
    width: 100%;
    height: 40px;
    font-size: 14px;
    margin-top: ${window.innerWidth < 1440 ? '32px' : '97px'};
    margin-bottom: 16px;
    outline: none;
    border: 1px solid ${props.color || ''};
    border-radius: 5px;
    cursor: pointer;
`);


const Text = styled.p`
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
`;

const Home = () => {

    // const [show, setShow] = useState(false);
    // const [alert, setAlert] = useState(false);
    // const [fail, setFail] = useState(false);
    const helpers = useSelector((state: any) => state.helpers);
    const history = useNavigate();
    const dispatch = useDispatch();
    const [logo, setLogo] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState<any>('grey');
    const params = useParams();
    const slug = params.slug || helpers.slug || '';

    useLayoutEffect(() => {
        dispatch({ type: SET_HELPERS, payload: { slug } });
        instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;

        if (!helpers.bearer) {
            const login = async () => {
                await instance.post('api/v1/LoginUsuario/correo', {
                    correo: "default@hotmail.com",
                    contrasena: "default.viutify.lilab",
                })
                    .then(res => {
                        dispatch({
                            type: SET_HELPERS,
                            payload: {
                                bearer: res.data.data
                            }
                        });
                        instance.defaults.headers.common.Authorization = `Bearer ${res.data.data}`;
                    })
                    .catch(() => {
                        dispatch({
                            type: SET_HELPERS,
                            payload: {
                                bearer: ''
                            }
                        });
                    })
            }
            login();
        }
        if (helpers.bearer && slug) {
            const setCompany = async () => {
                setLoading(true);
                await instance.get(`api/v1/DataSlug/data/${slug}`)
                    .then(res => {
                        localStorage.setItem('logo', res.data.data.logo);
                        setLogo(res.data.data.logo);
                        localStorage.setItem('color', res.data.data.codigoColor);
                        setColor(res.data.data.codigoColor);
                        dispatch({
                            type: SET_HELPERS,
                            payload: {
                                color: res.data.data.codigoColor,
                                companyId: res.data.data.idEmpresa,
                                company: res.data.data.nombreEmpresa,
                                idLocation: res.data.data.idLocal
                            }
                        });
                        return res;
                    }).then(res => { setLoading(false); return res; })
                    .catch(res => {
                        if (res.toString().includes('401')) {
                            const login = async () => {
                                await instance.post('api/v1/LoginUsuario/correo', {
                                    correo: "default@hotmail.com",
                                    contrasena: "default.viutify.lilab",
                                })
                                    .then(res => {
                                        dispatch({
                                            type: SET_HELPERS,
                                            payload: {
                                                bearer: res.data.data
                                            }
                                        });
                                        instance.defaults.headers.common.Authorization = `Bearer ${res.data.data}`;
                                    })
                            }
                            login();
                            history(`/${helpers.slug}`);
                        }
                        dispatch({ type: SET_HELPERS, payload: { user: {} } });
                        //history('/404');
                    });
            }
            if (slug) setCompany();
        }
        //eslint-disable-next-line
    }, [helpers.bearer]);

    //eslint-disable-next-line
    useEffect(() => { if (!slug) history('/404') }, []);

    // useEffect(() => { if(!helpers.user?.nombre || !helpers.bearer) setShow(true);}, [helpers.user?.nombre, helpers.bearer]);

    useEffect(() => {
        dispatch({
            type: SET_HELPERS,
            payload: {
                cities: [],
                locations: [],
                citySelected: '',
                locationSelected: { id: 0, name: '' },
                services: [],
                promotions: [],
                servicesFiltered: [],
                serviceChosen: {},
                categories: [],
                categoriesId: [],
                message: "Cargando...",
                day: "",
                hour: "",
                reservationId: "0",
            }
        })
        //eslint-disable-next-line
    }, []);

    return (
        loading ?
            <LoaderCircle color={helpers.color || color} pago={false} /> :

            <div style={{ backgroundColor: '#ffffff' }}>
                <Container>
                    <Section>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {logo ? (<img src={logo || ''} alt='logo' referrerPolicy="no-referrer"
                                style={{
                                    objectFit: 'contain',
                                    maxHeight: '200px',
                                    maxWidth: '300px',
                                    marginBottom: '32px',
                                }}
                            />) :
                                (<Text>
                                    {helpers.company}.
                                </Text>)}
                        </div>
                        <div className='buttons-Container'>
                            <Button color={color} onClick={() => { history('/reserva/sedes') }}>{window.innerWidth < 1440 ? 'Iniciar reserva' : 'Reserva una cita'}</Button>
                            {Object.keys(helpers.user).length === 0 && <ButtonLoginCorreo color={color} onClick={() => { history('/login') }}>Inicia sesión</ButtonLoginCorreo>}
                            {false ? <ButtonGoogleLogin width={window.innerWidth < 1440 ? 230 : 338}></ButtonGoogleLogin> : null}
                        </div>
                        {Object.keys(helpers.user).length === 0 && <p style={{ fontSize: '12px', textAlign: 'center' }}>¿Aún no tienes cuenta? <span style={{ fontWeight: '800', cursor: 'pointer' }} onClick={() => { history('/registro') }}>Regístrate aquí</span></p>}
                        {window.innerWidth >= 1440 &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <a href='https://viutify.com' target='_blank' rel='noreferrer'>
                                    <img src={powered} alt='powered by'
                                        style={{

                                            height: '16px',
                                            marginTop: '42px',
                                        }}
                                    />
                                </a>
                            </div>
                        }

                    </Section>
                    {window.innerWidth < 1440 &&
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <a href='https://viutify.com' target='_blank' rel='noreferrer'>
                                    <img src={powered} alt='powered by'
                                        style={{

                                            height: '16px',
                                            marginBottom: '24px',
                                        }}
                                    />
                                </a>
                            </div>
                            <p style={{fontSize: '10px', color: 'gray', textAlign: 'center'}}>V1.0.1</p>
                        </>
                    }

                </Container>

            </div>
    )
}

export default Home;
