import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ProfilePic from '../../Components/ProfilePic';
import { instance } from '../../../Providers/api';
import { useDispatch, useSelector } from 'react-redux';
import { SET_HELPERS } from '../../Redux/constants';
import search from '../../../assets/new-search-icon.svg';
import deleteSearch from '../../../assets/new-delete-search-icon.svg';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Section = styled.div<{ mobile?: boolean }>`
    background-color: #ffffff;
    font-family: 'Mulish';
    width: 100%;
    max-width: ${props => props.mobile ? '328px' : '656px'} ;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    
    
`;
const TopBar = styled.div`
    z-index: 3;
    display: flex;
    width: 100%;
    position: sticky;
    height: 74px;
    border-bottom: ${window.innerWidth < 1440 ? 'none' : '1px solid #000000'} ;
    box-sizing: border-box;
`;

const Title = styled.span`
color: #424242;
margin-top: 16px;
margin-bottom: 16px;
align-self: ${window.innerWidth < 1439 ? 'left' : 'center'};
font-size: ${window.innerWidth < 1439 ? '14px' : '16px'};
font-family: 'Mulish';
font-weight: 700;
`;


const Sedes = () => {

    const history = useNavigate();
    const helpers = useSelector((state: any) => state.helpers);
    const dispatch = useDispatch();
    const [logo, setLogo] = useState<any>();

    useLayoutEffect(() => {
        instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
        setLogo(localStorage.getItem('logo'));
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const getCities = async () => {
            const cities = await instance.get(`api/v1/Sedes/listar/${helpers.companyId}`)
                .then(res => { return res.data.data });
            dispatch({
                type: SET_HELPERS,
                payload: {
                    cities: [...cities],
                    citySelected: cities[0]
                },
            });
            if (cities.length === 1) {
                dispatch({
                    type: SET_HELPERS,
                    payload: {
                        locationSelected: cities[0].sedes[0]
                    },
                });
                history('/reserva/elegir-reserva');
            }
            if (helpers.idLocation) {
                var locations: any = [];
                cities.map((c: any) => locations = [...locations, ...c.sedes]);
                dispatch({ type: SET_HELPERS, payload: { locationSelected: locations.filter((l: any) => l.id_sede === helpers.idLocation)[0] } });
                console.log(locations.filter((l: any) => l.id_sede === helpers.idLocation)[0]);
                history('/reserva/elegir-reserva');
            }
           
        }
        getCities();

        //eslint-disable-next-line
    }, []);

    ;

    useEffect(() => {
        dispatch({
            type: SET_HELPERS,
            payload: {
                locations: [],
            },
        });
        if (helpers.citySelected.sedes && helpers.citySelected.sedes.length > 0) {
            dispatch({
                type: SET_HELPERS,
                payload: {
                    locations: [...helpers.citySelected.sedes]
                },
            });
        };
        //eslint-disable-next-line
    }, [helpers.citySelected]);

    const [listaDeSedes, setListaDeSedes] = useState<any>([]);
    const [sedeEncontrada, setSedeEncontrada] = useState<any>("");
    const [filtradoSedes, setFiltradoSedes] = useState<any>([]);

    useEffect(() => {
        const nuevalistaDeSedes: any = [];
        helpers.cities?.forEach((element: any) => {
            element.sedes.forEach((sede: any) => {
                nuevalistaDeSedes.push(
                    {
                        nombre: element.nombre_ciudad + ", " + sede.nombre_sede,
                        id_sede: sede.id_sede,
                        id_ciudad: element.id_ciudad,
                    }
                );
            });
        });
        setListaDeSedes(nuevalistaDeSedes);
    }, [helpers.cities]);

    const filtrarSedes = (element: any) => {
        setSedeEncontrada(element);
        const filtered = listaDeSedes.filter((pais: any) =>
            pais.nombre.toLowerCase().includes(element.toLowerCase())
        );
        setFiltradoSedes(filtered);
    }




    const elegirSede = (sede: any) => {
        console.log(sede)
        let cityS = [];
        cityS = helpers.cities?.filter((element: any) => element.id_ciudad === sede.id_ciudad)
        let sedeS = cityS[0].sedes.filter((element: any) => element.id_sede === sede.id_sede)
        console.log(cityS[0])
        console.log(sedeS[0])
        dispatch({ type: SET_HELPERS, payload: { citySelected: cityS[0] } })
        dispatch({ type: SET_HELPERS, payload: { locationSelected: sedeS[0] } })
    }

    return (<div style={{ backgroundColor: '#ffffff' }}>
        <Container>
            <TopBar>
                <div style={{ height: '74px', display: 'flex', alignItems: 'center' }}>
                    <img src={logo?.toString() || ''} alt='logo' style={{ height: '45px', marginLeft: '45px', cursor: "pointer" }} onClick={() => { history(`/${helpers.slug}`) }} />
                    <div style={{ position: 'absolute', right: '24px' }}><ProfilePic /></div>
                </div>
            </TopBar>
            <Scroll>
                <Section mobile={window.innerWidth < 600}>
                    <div style={{ position: 'sticky', top: '0', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: 'white' }}>
                        <Title>¿En qué sede deseas atenderte?</Title>
                        <InputSearch >

                            <div>
                                <img className='search' src={search} alt="search" />
                            </div>
                            <input placeholder='Buscar' value={sedeEncontrada} type="text" onChange={(e: any) => filtrarSedes(e.target.value)} />
                            <button onClick={() => setSedeEncontrada('')}>
                                {sedeEncontrada ? <img src={deleteSearch} alt="delete-search-input" /> : null}
                            </button>
                        </InputSearch>
                    </div>
                    <List>
                        {(sedeEncontrada ? filtradoSedes : listaDeSedes)?.map((sede: any, i: any) => <Sede key={i} onClick={() => { elegirSede(sede); history('/reserva/elegir-reserva') }}>
                            <p>{sede.nombre.split(' ').map((e: any) => e[0] + e.slice(1).toLowerCase()).join(' ')}</p>
                        </Sede>)}
                    </List>

                </Section>
            </Scroll>
        </Container>
    </div>)
}

export default Sedes;

const Scroll = styled.div`
// el scroll esta ocupando lugar, colo lo hago que este por encima?
width: 100%;
overflow-y: scroll;
overflow-x: hidden;
height: 100vh;
display: flex;
    flex-direction: column;
    align-items: center;
`

const List = styled.div`

display: flex;
flex-direction: column;
gap: 16px;
`;

const InputSearch = styled.div`
background-color: #ffffff;
align-items: center;
display: flex;
    border: 1px solid #000000;
    height: 42px;
    padding: 0px 24px;
    margin-bottom: 16px;
    border-radius: 3px;
    button{
        display: flex;
        border: none;
        background: none;
        
    }
    input{
        outline: none;
        font-family: 'Mulish', sans-serif;
        padding-left: 10px;
        height: 50%;
        border: none;
        background: none;
        width: 100%;

    }
    .search{
        display: flex;

    }
`;
const Sede = styled.div`
box-sizing: border-box;
cursor: pointer;
border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    border: 1px solid #424242;
    width: 100%;
    height: 45px;
    color: #424242;

    `;