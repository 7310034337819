import React,{useEffect,useState,useRef} from "react";
import styled from 'styled-components';
import ProfilePic from '../../Components/ProfilePic';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ElegirServicio from "../Servicios/ElegirServicio";
import ElegirFecha from "../Fecha/ElegirFecha";



const ReservaServicioYFecha = () => {
  const logo = localStorage.getItem('logo');
  const helpers = useSelector((state: any) => state.helpers);
  const history = useNavigate();
  const [vistaServicioFecha, setVistaServicioFecha] = useState<boolean>(true); //true = servicio, false = fecha
  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [loading, setLoading] = useState(true);

  const doneLoading = () => {
    setLoading(false);
  }

    useEffect(() => {
        if (divRef.current) {
          const divWidth = divRef.current.offsetWidth;
          setWidth(divWidth)
        }
    },[]);

  const changeVista = () => {
    setVistaServicioFecha(!vistaServicioFecha);
  }

  return(<>
    <Container ref={divRef}>
      <TopBar>
        <div style={{height:'74px', display:'flex',alignItems:'center'}}>
        <img src={logo?.toString() || ''} alt='logo' style={{height: '45px', marginLeft: `${width>=1440?'45px':'24px'}`, cursor: "pointer"}} onClick={() => {history(`/${helpers.slug}`)}}/>
        <div style={{position: 'absolute', right: '24px'}}><ProfilePic/></div>
        </div>
       </TopBar>
        <Section width={width} loading={loading}>
          {console.log(width)}
          {(width>=1440)?(<>
              <div className="servicio">
                <ElegirServicio width={width} changeVista={changeVista}
                loading={loading} doneLoading={doneLoading}
                ></ElegirServicio>
              </div>
              <div className="fecha">
                <ElegirFecha loading={loading}  width={width} changeVista={changeVista}></ElegirFecha>
              </div>
              </>)
              :
              (vistaServicioFecha?(<div className="servicio">
                <ElegirServicio  width={width} changeVista={changeVista}
                loading={loading} doneLoading={doneLoading}
                ></ElegirServicio>
              </div>) : 
              <div className="fecha">
                <ElegirFecha width={width} changeVista={changeVista}></ElegirFecha>
              </div>)
          }
          </Section>
    </Container>
  </>)

};
const TopBar = styled.div`
    z-index: 3;
    display: flex;
    width: 100%;
    position: sticky;
    height: 74px;
    border-bottom: ${window.innerWidth<1440?'none':'1px solid #000000'} ;
    box-sizing: border-box;
`;


const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

`;

const Section = styled.div<{width: number,loading:boolean}>`
    background-color: white;
    font-family: 'Mulish';
    height: 100%;
    width: 100%;
    font-size: 18px;
    display: grid;
    grid-template-columns: ${props => props.width<1440?'100%':'50% 50%'} ;
    
    .servicio{
      position: relative;
      height: calc(100vh - 74px);
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .fecha{
      border-left: ${props => props.width<1440?'none': props.loading?'1px solid #77767A':'none'} ;
      box-sizing: border-box;
      position: relative;
      height: calc(100vh - 74px);
      width: 100%;
      display: flex;
      justify-content: center;
      
    }
   
    
    
`;

export default ReservaServicioYFecha