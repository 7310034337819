import React, { useState, useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import search from "../../../assets/new-search-icon.svg";
import deleteSearch from "../../../assets/new-delete-search-icon.svg";
import filter from "../../../assets/new-filter-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { SET_HELPERS } from "../../../State/redux/constants/helpers";
import { instance } from "../../../Providers/api";
import BackNavButton from "../../../Components/elements/Navigation/backNavButton";
import newCheckCircle from "../../../assets/new-check-circle.svg";
import Service from "./Service";
import newExitIcon from "../../../assets/new-exit-icon.svg";
import newCheckCuad from "../../../assets/new-check-cuad.svg";
import backArrow from "../../../assets/back-arrow-icon.svg";

interface Vista {
  changeVista: any;
  width: number;
  doneLoading?: any;
  loading?: boolean;
}

const ElegirServicio: React.FC<Vista> = ({
  width,
  changeVista,
  doneLoading,
  loading,
}) => {
  const [color, setColor] = useState<any>("white");
  const helpers = useSelector((state: any) => state.helpers);
  const [isFilterByPromociones, setFilterPromociones] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const [servicios, setServicios] = useState<any>([]);
  const [promociones, setPromociones] = useState<any>([]);
  const [filtered, setFiltered] = useState<any>([]);
  const [buscar, setBuscar] = useState<any>("");
  const [categories, setCategories] = useState<any>();
  const [selectedCats, setSelectedCats] = useState<any>([]);
  const [listaOriginal, setListaOriginal] = useState<any>([]);

  const capitalize = (s: string) => {
    return s
      .split(" ")
      .map((e: any) => e[0] + e.slice(1).toLowerCase())
      .join(" ");
  };
  useEffect(() => {
    setColor(localStorage.getItem("color"));
  }, []);

  useLayoutEffect(() => {
    instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
    dispatch({
      type: SET_HELPERS,
      payload: { stylistSelected: "", hour: "", day: "" },
    });
    instance
      .post("api/v1/Categoria/listar", {
        idEmpresa: helpers.companyId,
      })
      .then((res) => setCategories([...res.data.data]));
    instance
      .get(
        `api/v1/Servicios/${helpers.companyId}/${helpers.locationSelected?.id_sede}`
      )
      .then((res) => {
        setServicios([...res.data.data]);
      });
    instance
      .post("api/v1/Promociones/listarPromocion", {
        id_local: helpers.locationSelected?.id_sede,
        id_empresa: helpers.companyId,
      })
      .then((res) => {
        (res.data.data.length === 0 ||
          helpers.locationSelected.tipoPromocionOServicios === 3) &&
          setFilterPromociones(false);
        setPromociones([...res.data.data]);
      });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (helpers.locationSelected.tipoPago === 3) {
      dispatch({
        type: SET_HELPERS,
        payload: {
          serviceChosen: {
            idservicio: -1,
            nombre: "Pago adelantado",
            precio: helpers.locationSelected?.montoParcialPago,
          },
        },
      });
      changeVista();
    } else {
      dispatch({
        type: SET_HELPERS,
        payload: {
          serviceChosen: {},
        },
      });
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    generarListaCompleta();
    //eslint-disable-next-line
  }, [promociones, servicios]);

  const generarListaCompleta = () => {
    const newFormate = [
      ...promociones.map((e: any) => {
        return {
          ...e,
          nombre: e.nombrePromocion,
          precio: e.precioPromocion,
        };
      }),
      ...servicios,
    ];

    setFiltered(newFormate);
    setListaOriginal(newFormate);
  };

  const filterBySearch = (inputValue: string) => {
    setBuscar(inputValue);
    let newList = [];
    if (inputValue.length >= 2) {
      newList = listaOriginal.filter((e: any) =>
        e.nombre.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFiltered(newList);
    } else if (inputValue === "") {
      setFiltered(listaOriginal);
    }
  };

  const seleccionarServicio = (servicio: any) => {
    let encontrado = {};
    if (servicio.idservicio) {
      encontrado = listaOriginal.find(
        (e: any) => e.idservicio === servicio.idservicio
      );
    } else if (servicio.idPromocion) {
      const{idPromocion,nombrePromocion,precioPromocion,horaInicio,horaFin,terminos,diasNoAplica,serviciosPromocion} = servicio;
      encontrado = {idPromocion,nombrePromocion,precioPromocion,horaInicio,horaFin,terminos,diasNoAplica,serviciosPromocion};
    }

    dispatch({
      type: SET_HELPERS,
      payload: {
        serviceChosen: encontrado,
      },
    });

    changeVista();
  };
  console.log(helpers.serviceChosen,"elegido");

  const [modelOpen, setModelOpen] = useState(false);
  const [ordenadoPor, setOrdenadoPor] = useState("default");
  const showModal = () => {
    setModelOpen(!modelOpen);
  };
  const ordenarPorPrecio = (value: string) => {
    const newFormate = [...listaOriginal];
    if (value === "default") {
      setFiltered(newFormate);
      setOrdenadoPor("default");
    } else if (value === "asc") {
      const newList = newFormate.sort((a: any, b: any) => a.precio - b.precio);
      setFiltered(newList);
      setOrdenadoPor("asc");
    } else if (value === "desc") {
      const newList = newFormate.sort((a: any, b: any) => b.precio - a.precio);
      setFiltered(newList);
      setOrdenadoPor("desc");
    }
  };
  const [catActive, setCatActive] = useState<boolean>(false);
  const switchCategories = () => {
    setCatActive(!catActive);
  };
  useEffect(() => {
    if (catActive) {
      setSelectedCats([...categories]);
    } else {
      setSelectedCats([]);
    }
    //eslint-disable-next-line
  }, [catActive]);

  useEffect(() => {
    if (listaOriginal.length > 0) {
      doneLoading();
    }
  }, [selectedCats, listaOriginal, doneLoading]);

  const [buscadorMobile, setBuscadorMobile] = useState(false);

  return (
    <>
      {!loading ? (
        <Container>
          <NavigationService>
            <BackNavButton text='Seleccionar servicio' url={""} />
            <ButtonChecked
              color={color}
              active={isFilterByPromociones}
              onClick={() => setFilterPromociones(!isFilterByPromociones)}>
              <button>
                {isFilterByPromociones ? (
                  <img src={newCheckCircle} alt='check-filter' />
                ) : null}
              </button>
              <span>Ver solo promociones</span>
            </ButtonChecked>
          </NavigationService>
          {buscadorMobile?<><FilterComponent>
            <Buscador>
              <div>
                <img className='search' src={search} alt='search' />
              </div>
              <input
                placeholder='Buscar'
                value={buscar}
                type='text'
                onChange={(e: any) => {
                  filterBySearch(e.target.value);
                }}
              />
              <button onClick={() => filterBySearch("")}>
                {buscar ? (
                  <img src={deleteSearch} alt='delete-search-input' />
                ) : null}
              </button>
            </Buscador>
          </FilterComponent></> :null}

          {width>=1440?<FilterComponent>
            <Buscador>
              <div>
                <img className='search' src={search} alt='search' />
              </div>
              <input
                placeholder='Buscar'
                value={buscar}
                type='text'
                onChange={(e: any) => {
                  filterBySearch(e.target.value);
                }}
              />
              <button onClick={() => filterBySearch("")}>
                {buscar ? (
                  <img src={deleteSearch} alt='delete-search-input' />
                ) : null}
              </button>
            </Buscador>
            <div className='filter' onClick={() => showModal()}>
              <img src={filter} alt='filter' />
            </div>
          </FilterComponent>

          :
          <FilterDesign>
            <div className='filter' onClick={() => showModal()}>
              <img src={filter} alt='filter' />
            </div>
          <div className='filter' onClick={()=>setBuscadorMobile(true)}>
            <img className='search' src={search} alt='search' />
          </div> 
          </FilterDesign>}
          {buscadorMobile ? (<>
            
          <BuscadorMobile>
            <BackButton onClick={() => setBuscadorMobile(false)}>
                <img src={backArrow} alt='go-to-reserva' />
                Buscar
              </BackButton>
            <div className="mensaje">
              <p>¿Qué deseas buscar?</p>
            </div>
          </BuscadorMobile>
        </>
          ):null}
          
          <ListOfServices>
            {isFilterByPromociones
              ? filtered.map((e: any) => {
                  if (e.idPromocion > 0)
                    return (
                      <Service
                      isSelected={helpers.serviceChosen.idPromocion===e.idPromocion}
                        seleccionarServicio={seleccionarServicio}
                        color={color}
                        data={e}
                        key={e.idPromocion}
                      />
                    );
                  else return null;
                })
              : filtered.map((e: any) => {
                  return (
                    <Service
                    isSelected={e.idPromocion>0?helpers.serviceChosen.idPromocion===e.idPromocion:
                      e.idservicio?helpers.serviceChosen.idservicio===e.idservicio:false}
                      seleccionarServicio={seleccionarServicio}
                      color={color}
                      data={e}
                      key={e.idservicio?e.idservicio:e.idPromocion}
                    />
                  );
                })}
          </ListOfServices>
        </Container>
      ) : (
        <Container>
          <LoaderCard height='48px'>
            <div className='space'></div>
          </LoaderCard>
          <LoaderCard height='48px'>
            <div className='space'></div>
          </LoaderCard>
          <div style={{ overflow: "hidden" }}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((e: any) => {
              return (
                <LoaderCard height='96px' key={e}>
                  <div className='space'></div>
                </LoaderCard>
              );
            })}
          </div>
        </Container>
      )}
      {modelOpen ? (
        <ModalFilter color={color} mobile={width < 1440}>
          <div className='modal'>
            <div className='title' onClick={() => showModal()}>
              <img src={newExitIcon} alt='exit-filter' /> Filtros
            </div>
            <div className='title'>Ordenar</div>
            <div className='display'>
              <ButtonChecked
                color={color}
                active={ordenadoPor === "default"}
                onClick={() => ordenarPorPrecio("default")}>
                <button>
                  {ordenadoPor === "default" ? (
                    <img src={newCheckCircle} alt='check-filter' />
                  ) : null}
                </button>
                <span>Orden Predeterminado</span>
              </ButtonChecked>
              <ButtonChecked
                color={color}
                active={ordenadoPor === "asc"}
                onClick={() => ordenarPorPrecio("asc")}>
                <button>
                  {ordenadoPor === "asc" ? (
                    <img src={newCheckCircle} alt='check-filter' />
                  ) : null}
                </button>
                <span>Precio más bajo primero</span>
              </ButtonChecked>
              <ButtonChecked
                color={color}
                active={ordenadoPor === "desc"}
                onClick={() => ordenarPorPrecio("desc")}>
                <button>
                  {ordenadoPor === "desc" ? (
                    <img src={newCheckCircle} alt='check-filter' />
                  ) : null}
                </button>
                <span>Precio más alto primero</span>
              </ButtonChecked>
            </div>
            <div className='title'>
              Categorías de servicio:
              <div className='categoriasTodas'>
                {" "}
                <Checked
                  selected={catActive}
                  onClick={() => switchCategories()}></Checked>{" "}
                Todas{" "}
              </div>
            </div>
            <div className='contenedor'>
              {categories.map((c: any, i: any) => (
                <ButtonCheckedA
                  key={i}
                  onClick={() => {
                    selectedCats
                      .map((cat: any) => cat.id_categoria)
                      .includes(c.id_categoria)
                      ? setSelectedCats(
                          selectedCats.filter(
                            (cat: any) => cat.id_categoria !== c.id_categoria
                          )
                        )
                      : setSelectedCats([...selectedCats, c]);
                  }}
                  color={color}
                  active={selectedCats
                    .map((cat: any) => cat.id_categoria)
                    .includes(c.id_categoria)}>
                  <button>
                    {selectedCats
                      .map((cat: any) => cat.id_categoria)
                      .includes(c.id_categoria) ? (
                      <img src={newCheckCuad} alt='check-list' />
                    ) : null}
                  </button>
                  <span>{capitalize(c.nombre_categoria)}</span>
                </ButtonCheckedA>
              ))}
            </div>

            <div className='buttonCat'>
              <button
                onClick={() => {
                  if (selectedCats.length > 0) {
                    setServicios([]);
                    var serviciosCategorias: any = [];
                    selectedCats.map(async (c: any) => {
                      await instance
                        .get(
                          `api/v1/Servicios/${helpers.companyId}/${helpers.locationSelected.id_sede}/${c.id_categoria}`
                        )
                        .then((res: any) => {
                          serviciosCategorias = [
                            ...serviciosCategorias,
                            ...res.data.data,
                          ];
                        });
                      setServicios([...serviciosCategorias]);
                      return null;
                    });
                  } else {
                    instance
                      .get(
                        `api/v1/Servicios/${helpers.companyId}/${helpers.locationSelected.id_sede}`
                      )
                      .then((res: any) => {
                        setServicios([...res.data.data]);
                      });
                  }
                }}>
                Confirmar categoria
              </button>
            </div>
          </div>
        </ModalFilter>
      ) : null}
    </>
  );
};

export default ElegirServicio;

const BackButton = styled.div`
  
  cursor: pointer;
  background:none;
  font-family: "Mulish";
  display: flex;
  border: none;
  font-size: 14px;
  align-items: center;
  color: #1b1b1f;
  gap: 30px;
  font-weight: 700;
  padding-left: 8px;

  img {
    
    padding: 0;
    margin: 0;
  }
`;

const LoaderCard = styled.div<{ height: string }>`
  .space {
    height: ${(props) => props.height};
  }
  width: 100%;
  border-radius: 4px;
  height: ${(props) => props.height};
  margin-bottom: 16px;

  background: #eee;
  background: linear-gradient(90deg, #cdcdcd 8%, #e9e9e9 30%, #cdcdcd 48%);
  background-size: 200% 100%;
  animation: 1.2s shine linear infinite;
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

const ButtonCheckedA = styled.div<{ color: string; active: boolean }>`
  background-color: ${(props) => (!props.active ? "#FAFAFA" : props.color)};
  height: 28px;
  width: fit-content;
  display: flex;
  color: ${(props) => (!props.active ? "#000000" : "#FAFAFA")};
  box-sizing: border-box;
  align-items: center;
  gap: 6px;
  border: 1px solid ${(props) => (props.active ? "#FAFAFA" : "#77767A")};
  border-radius: 30px;
  padding: 0px 8px;
  button {
    padding: 0;
    margin: 0;
    background: none;
    outline: none;
    border: ${(props) => (!props.active ? "2px solid #77767A" : "none")};
    width: 14px;
    height: 14px;
    border-radius: 1px;
  }
  span {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    align-items: center;
    display: inline;
  }
`;

const Checked = styled.button<{ selected: boolean }>`
  padding: 0;
  margin: 0;
  background: ${(props) => (props.selected ? "#77767A" : "#ffffff")};
  outline: none;
  border: 2px solid #77767a;
  width: 14px;
  height: 14px;
  border-radius: 3px;
`;

const ModalFilter = styled.div<{ color?: string; mobile: boolean }>`
  position: fixed;
  left: 1;
  bottom: 0;
  width: ${(props) => (props.mobile ? "100vw" : "50vw")};
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.mobile ? "end" : "center")};
  background: none;
  z-index: 1;
  .buttonCat {
    display: flex;
    justify-content: end;
    button {
      color: #000000;
      padding: 4px 8px;
      border: 1px solid ${(props) => props.color || "#e4e4e4"};
      outline: none;
      border-radius: 4px;
      background: none;
      font-family: "Mulish", sans-serif;
    }
  }
  .contenedor {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 6px;
    overflow-y: scroll;
  }
  .display {
    display: grid;
    gap: 6px;
  }
  .modal {
    position: relative;
    display: flex;
    flex-direction: column;

    gap: 16px;
    padding: 24px;
    border: 1px solid #77767a;
    border-bottom: ${(props) => (props.mobile ? "none" : "1px solid #77767A")};
    background-color: white;
    width: ${(props) => (props.mobile ? "100%" : "390px")};
    height: ${(props) => (props.mobile ? "80%" : "470px")};
    border-radius: ${(props) => (props.mobile ? "8px 8px 0px 0px" : "4px")};
  }
  .title {
    display: flex;
    gap: 26px;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
  }
  .categoriasTodas {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const ListOfServices = styled.div`
  overflow-y: auto;
  max-height: 600px;
  display: flex;
  flex-direction: column;
`;
const BuscadorMobile = styled.div`
background-color: #ffffff;
position: fixed;
top: 0;
right: 0;
z-index: 4;
height: 128px;
width: 100%;
padding: 16px 16px 22px 16px;
box-sizing: border-box;
.mensaje{
  height: 100%;
  display: flex;
  align-items: end;
  p{
    font-size: 14px;
    font-weight: 700;
    color: #1B1B1F;
  }
}
`

const FilterDesign = styled.div`
position: fixed;
top: 8px;
right: 72px;
z-index: 4;

width: fit-content;
display: flex;
margin: 0;
padding: 0;
height: 72px;
padding-top: 6px;
.filter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px; 
    margin: 0;
    padding: 0;
    cursor: pointer;
    img{
      margin: 0;
    padding: 0;
    }
  }
`

const FilterComponent = styled.div`
  display: flex;
  .filter {
    z-index: 2;
    padding: 15px;
    cursor: pointer;
  }
`;

const Buscador = styled.div`
  width: 100%;
  background-color: #ffffff;
  align-items: center;
  display: flex;
  border: 1px solid #000000;
  height: 42px;
  padding: 0px 24px;
  margin-bottom: 16px;
  border-radius: 3px;
  button {
    display: flex;
    border: none;
    background: none;
  }
  input {
    outline: none;
    font-family: "Mulish", sans-serif;
    padding-left: 10px;
    height: 50%;
    border: none;
    background: none;
    width: 100%;
  }
  .search {
    display: flex;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  padding: 0px 16px 0px 16px;
  background-color: #ffffff;
  width: 684px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const NavigationService = styled.div`
  margin-top: ${window.innerWidth >= 1440 ? "16px":"0px"};
  margin-bottom: 26px;
  ${window.innerWidth >= 1440 ? "height: 48px":""};
  
  background:none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonChecked = styled.div<{ color: string; active: boolean }>`
  cursor: pointer;
  background-color: ${(props) => (!props.active ? "#FAFAFA" : props.color)};
  height: 28px;
  width: fit-content;

  color: ${(props) => (!props.active ? "#000000" : "#FAFAFA")};
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid ${(props) => (props.active ? "#FAFAFA" : "#77767A")};
  border-radius: 30px;
  box-sizing: border-box;
  padding: 0px 8px;
  button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    background: none;
    outline: none;
    border: ${(props) => (!props.active ? "2px solid #77767A" : "none")};
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  span {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    align-items: center;
  }
`;
