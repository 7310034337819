//1. Libraries
import React, { useEffect } from "react";
import ReactDOM from "react-dom";

//2. Components

//3. Fetchers

//4. Functions

//5. Styled Components
import { Container, Text } from "./styles";
import "./styles.css"

//6. Types

export default function Loader() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])
  const root = document.getElementById("root");

  const loader = (
    <Container>
      {/* <Loading>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Loading> */}
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      <Text>Cargando</Text>
    </Container>
  );

  return root ? ReactDOM.createPortal(loader, root) : null;
}
