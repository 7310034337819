import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { instance } from '../../../Providers/api';
import flecha from '../../../assets/left-arrow.svg';
import Alert from '../../Components/Alert';
import styled from 'styled-components';

const Container = styled.div`
    width: 100vw;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #E5E5E5;
`;

const Section = styled.div`
    background-color: white;
    width: 100vw;
    max-width: 600px;
    margin-top: 43px;
    border-radius: 20px 20px 0 0;
    font-size: 18px;
    display: flex;
    justify-content: center;
`;

const VerificarCorreo = () => {

    const history = useNavigate();
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const helpers = useSelector((state: any) => state.helpers);
    const logo = localStorage.getItem('logo');
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
        if(!token) {history(`/${helpers.slug}`); return;}
        verifyEmail();
    //eslint-disable-next-line
    }, []);

    const verifyEmail = () => {
        instance.post('/NuevoUsuario/validarcorreo', {
            token: token,
        })
        .then(() => setSuccess(true))
        .catch(() => setFail(true));
    }

    return (
        <>
        {success ? <Alert setShow={setSuccess} title='Email verificado correctamente ' image={'success'} backUrl={`/${helpers.slug}`} borderColor={'#0DCA64'}/> : null}
        {fail ? <Alert setShow={setFail} title='Hubo un error al verificar su email' image={'fail'} backUrl={`/${helpers.slug}`} borderColor={'#CA450D'}/> : null}
        <div style={{display: 'flex', justifyContent: 'center', color: '#727070'}}>
            <Container>
                <img src={flecha} alt='back' style={{marginTop: '10px', marginLeft: '10px', alignSelf: 'flex-start'}} onClick={() => {history(`/${helpers.slug}`)}}/>
                <p style={{marginTop: '0px'}}>¡Hola!</p>
                <img src={logo || ''} alt='logo' style={{height: '97px', marginTop: '0px'}}/>
                <p style={{fontWeight: '800', marginTop: '20px'}}>Reservas</p>
                <Section>
                    <div style={{width: '80vw', maxWidth: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{fontWeight: '800', marginTop: '30px', textAlign: 'left', width: '80vw', maxWidth: '300px'}}>Su dirección de email esta siendo verificada</p>
                    </div>
                </Section>
            </Container>
        </div>
        </>
    )
}

export default VerificarCorreo;