import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import ProfilePic from '../../Components/ProfilePic';
import leftArrow from '../../../assets/left-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { instance } from '../../../Providers/api';
import Alert from '../../Components/Alert';
import { SET_USER } from '../../Redux/constants';

const Container = styled.div`
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
    color: #727070;
`;

const Section = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #e7e7e7;
    position: relative;
`;

const TopBar = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 15px;
`;

const Navigation = styled.div`
    display: flex;
    width: 100%;
    margin-top: 15px;
`;

const Title = styled.span`
    font-size: 20px;
    margin-left: 15px;
    font-weight: 800;
`;

const BodyContainer = styled.div`
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 480px;
    margin-top: 30px;
    padding-top: 20px;
`;

const Perfil = () => {

    const logo = localStorage.getItem('logo');
    const history = useNavigate();
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [datos, setDatos] = useState({
        nombre: '',
        celular: '',
        fechaNac: '',
        correo: '',
        contrasena: '',
        confirmar: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
    });
    const helpers = useSelector((state: any) => state.helpers);
    const dispatch = useDispatch();

    const formatDateToInput = (s: string) => {
        let arr = s.split('-');
        if(arr[2] === undefined) return '';
        return arr[0] + '-' + ('0' + arr[1]).slice(-2) + '-' + ('0' + arr[2]).slice(-2);
    }

    useLayoutEffect(() => {
        instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
        setDatos({
            nombre: helpers.user.nombre,
            apellidoPaterno: helpers.user.apellidoPaterno,
            apellidoMaterno: helpers.user.apellidoMaterno,
            correo: helpers.user.email,
            fechaNac: formatDateToInput(helpers.user.fechaNacimiento),
            celular: helpers.user.telefono || '',
            confirmar: '',
            contrasena: '',
        })
    //eslint-disable-next-line
    }, []);

    const handleActualizar = () => {
        if(!datos.nombre || !datos.celular
            || !datos.apellidoMaterno || !datos.apellidoPaterno) {alert('Complete todos sus datos'); return;}
        if(datos.contrasena !== datos.confirmar) {alert('Las contraseñas no coinciden'); return;}
        instance.patch('actualizardatos', {
            nombres: datos.nombre,
            apellidoPaterno: datos.apellidoPaterno,
            apellidoMaterno: datos.apellidoMaterno,
            celular: datos.celular,
            fechaNacimiento: datos.fechaNac,
            password: datos.contrasena,
        })
        .then(res => {
            if(res.data.status === 200) {
                instance.get('datausuario')
                .then(res => {
                    dispatch({
                        type: SET_USER,
                        payload: { ...res.data.data }});
                })
                setSuccess(true);
            } 
            else setFail(true);
        })
    }

    return (
        <>
            {success ? <Alert setShow={setSuccess} title='Datos actualizados con éxito' image={'success'} backUrl={-1} borderColor={'#0DCA64'}/> : null}
            {fail ? <Alert setShow={setFail} title='Ocurrió un error al actualizar sus datos.' image={'fail'} borderColor={'#CA450D'}/> : null}
            <Container>
                <Section>
                    <TopBar>
                        <img src={logo || ''} alt='logo' style={{height: '30px', marginLeft: '40px'}} onClick={() => {history(-1)}}/>
                        <div style={{position: 'absolute', right: '20px'}}><ProfilePic/></div>
                    </TopBar>
                    <Navigation>
                        <img src={leftArrow} style={{width: '10px', marginLeft: '15px'}} alt='back' onClick={() => {history(-1)}}/>
                        <Title>
                            Confirma tus datos
                        </Title>
                    </Navigation>
                    <BodyContainer>
                        <div 
                            style={{
                                backgroundColor: 'white',
                                width: '75%',
                                fontSize: '18px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <p style={{fontSize: '18px', margin: '0', marginTop: '15px'}}>Nombre</p>
                            <input type='text' value={datos.nombre} onChange={(e: any) => setDatos({...datos, nombre: e.target.value})}
                                style={{
                                    margin: '0', 
                                    height: '32px',
                                    maxWidth: '100%', 
                                    marginTop: '5px', 
                                    borderRadius: '5px', 
                                    border: '1px solid #727070', 
                                    outline: 'none',
                                    padding: '0 10px',
                                    color: '#727070'
                                    }}
                            />
                            <p style={{fontSize: '18px', margin: '0', marginTop: '15px'}}>Apellido Paterno</p>
                            <input type='text' value={datos.apellidoPaterno} onChange={(e: any) => setDatos({...datos, apellidoPaterno: e.target.value})}
                                style={{
                                    margin: '0', 
                                    height: '32px',
                                    maxWidth: '100%', 
                                    marginTop: '5px', 
                                    borderRadius: '5px', 
                                    border: '1px solid #727070', 
                                    outline: 'none',
                                    padding: '0 10px',
                                    color: '#727070'
                                    }}
                            />
                            <p style={{fontSize: '18px', margin: '0', marginTop: '15px'}}>Apellido Materno</p>
                            <input type='text' value={datos.apellidoMaterno} onChange={(e: any) => setDatos({...datos, apellidoMaterno: e.target.value})}
                                style={{
                                    margin: '0', 
                                    height: '32px',
                                    maxWidth: '100%', 
                                    marginTop: '5px', 
                                    borderRadius: '5px', 
                                    border: '1px solid #727070', 
                                    outline: 'none',
                                    padding: '0 10px',
                                    color: '#727070'
                                    }}
                            />
                            <p style={{fontSize: '18px', margin: '0', marginTop: '15px'}}>Celular</p>
                            <input type='text' value={datos.celular} onChange={(e: any) => setDatos({...datos, celular: e.target.value})}
                                style={{
                                    margin: '0', 
                                    height: '32px', 
                                    maxWidth: '100%', 
                                    marginTop: '5px', 
                                    borderRadius: '5px', 
                                    border: '1px solid #727070', 
                                    outline: 'none',
                                    padding: '0 10px',
                                    color: '#727070'
                                    }}
                            />
                            <p style={{fontSize: '18px', margin: '0', marginTop: '15px'}}>Fecha de nacimiento</p>
                            <input type='date' value={datos.fechaNac} onChange={(e: any) => {setDatos({...datos, fechaNac: e.target.value});}}
                                style={{
                                    margin: '0', 
                                    height: '32px', 
                                    maxWidth: '100%', 
                                    marginTop: '5px', 
                                    borderRadius: '5px', 
                                    border: '1px solid #727070', 
                                    outline: 'none',
                                    padding: '0 10px',
                                    color: '#727070'
                                    }}
                            />
                            <p style={{fontSize: '18px', margin: '0', marginTop: '15px'}}>Correo</p>
                            <input type='text' disabled={true} value={datos.correo} onChange={(e: any) => setDatos({...datos, correo: e.target.value})}
                                style={{
                                    margin: '0', 
                                    height: '32px', 
                                    maxWidth: '100%', 
                                    marginTop: '5px', 
                                    borderRadius: '5px', 
                                    border: '1px solid #727070', 
                                    outline: 'none',
                                    padding: '0 10px',
                                    color: '#9e9e9e'
                                    }}
                            />
                            {!helpers.user.cuentaGoogle ?
                            <>
                                <p style={{fontSize: '18px', margin: '0', marginTop: '15px'}}>Contraseña</p>
                                <input type='password' value={datos.contrasena} onChange={(e: any) => setDatos({...datos, contrasena: e.target.value})}
                                    style={{
                                        margin: '0', 
                                        height: '32px', 
                                        maxWidth: '100%', 
                                        marginTop: '5px', 
                                        borderRadius: '5px', 
                                        border: '1px solid #727070', 
                                        outline: 'none',
                                        padding: '0 10px',
                                        color: '#727070'
                                        }}
                                />
                                <p style={{fontSize: '18px', margin: '0', marginTop: '15px'}}>Confirmar contraseña</p>
                                <input type='password' value={datos.confirmar} onChange={(e: any) => setDatos({...datos, confirmar: e.target.value})}
                                    style={{
                                        margin: '0', 
                                        height: '32px', 
                                        maxWidth: '100%', 
                                        marginTop: '5px', 
                                        borderRadius: '5px', 
                                        border: '1px solid #727070', 
                                        outline: 'none',
                                        padding: '0 10px',
                                        color: '#727070'
                                        }}
                                />
                            </> : null}
                            <button onClick={e => {e.preventDefault(); handleActualizar();}}
                                style={{
                                    color: 'white', 
                                    backgroundColor: helpers.color || '', 
                                    height: '40px', 
                                    maxWidth: '100%', 
                                    outline: 'none', 
                                    border: 'none',
                                    borderRadius: '5px',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    fontSize: '14px'
                                }}
                            >
                                Confirmar
                            </button>
                        </div>
                    </BodyContainer>
                </Section>
            </Container>
        </>
    )
}

export default Perfil;