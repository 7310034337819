import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import userImg from "../../../assets/user-img.png";
import { SET_HELPERS } from "../../Redux/constants";
import Alert from "../Alert";

const Container = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${localStorage.getItem("color")};
  color: white;
  position: relative;
  padding: 0;
  margin: 0;
`;

const Option = styled.span`
  cursor: pointer;
  :hover {
    background-color: #f5f5f5;
    border-radius: 4px;
  }
  padding: 5px 5px;
`;

const ProfilePic = () => {
  const helpers = useSelector((state: any) => state.helpers);
  const history = useNavigate();
  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);

  return (
    <>
      {alert ? (
        <Alert
          setShow={setAlert}
          title='Sesión cerrada con éxito.'
          image={"success"}
          backUrl={`/${helpers.slug}`}
          borderColor={"#0DCA64"}
        />
      ) : null}
      <Container
        onClick={() => {
          helpers.user.nombre ? setPopup(!popup) : history("/login");
        }}>
        {helpers.user.nombre ? (
          localStorage.getItem("image") ? (
            <img
              onClick={() => setPopup(!popup)}
              src={localStorage.getItem("image")?.toString()}
              referrerPolicy='no-referrer'
              style={{
                width: "36px",
                height: "36px",
                padding: "0",
                borderRadius: "50%",
                cursor: "pointer",
                
              }}
              alt='user'
            />
          ) : (
            <p
              onClick={() => setPopup(!popup)}
              style={{
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "36px",
                margin: "0",
                padding: "0",
                cursor: "pointer",
              }}>
              {helpers.user.nombre[0].toUpperCase()}
            </p>
          )
        ) : (
          <img
            onClick={() => setPopup(!popup)}
            src={userImg}
            style={{
              width: "36px",
              height: "36px",
              padding: "0",
              borderRadius: "50%",
              cursor: "pointer",
              
            }}
            alt='user'
          />
        )}
      </Container>
      {popup ? (
        <div
          style={{
            width: "120px",
            position: "fixed",
            top: "50px",
            right: "24px",
            backgroundColor: "#ffffff",
            padding: "4px 4px",
            boxShadow: "0px 0px 8px 2px rgba(156, 156, 156, 0.397)",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",}}>
          <div style={{ display: "flex" }}>
            <span style={{ fontWeight: "bold", marginLeft: "5px",marginBottom:"5px" }}>
              Opciones
            </span>
            <span
              style={{
                marginLeft: "auto",
                marginRight: "8px",
                cursor: "pointer",
              }}
              onClick={() => setPopup(!popup)}>
              x
            </span>
          </div>
          <Option
            onClick={() => {
              !helpers.user.dataDctoYNrTelf
                ? history("/completar-datos")
                : history("/editar-perfil");
            }}>
            Editar perfil
          </Option>
          <Option
            onClick={() => {
              dispatch({
                type: SET_HELPERS,
                payload: { user: {}, bearer: "" },
              });
              setAlert(true);
            }}>
            Cerrar sesión
          </Option>
        </div>
      ) : null}
    </>
  );
};

export default ProfilePic;
