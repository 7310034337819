//1. Libraries
import React, { Suspense } from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import Loader from "../../Components/elements/Loader/Loader";
import CambiarContraseña from "../Pages/CambiarContraseña";
import CompletarDatos from "../Pages/CompletarDatos";
import Confirmacion from "../Pages/Confirmacion";
import Fecha from "../Pages/Fecha";
import Home from "../Pages/Home";
import LandingPage from "../Pages/Landing";
import Login from "../Pages/Login";
import NotFound from "../Pages/NotFound";
import Pago from "../Pages/Pago";
import Perfil from "../Pages/Perfil";
import RecuperarContraseña from "../Pages/RecuperarContraseña";
import Registro from "../Pages/Registro";
import Resumen from "../Pages/Resumen";
import Sedes from "../Pages/Sedes";
import Servicios from "../Pages/Servicios";
import VerificarCorreo from "../Pages/VerificarCorreo";
import ReservaServicioYFecha from "../Pages/ReservaServicioYFecha/ReservaServicioYFecha";
import Prueba from "../Pages/Confirmacion/Prueba";

//2. Components

//3. Fetchers

//4. Functions

//5. Styled Components

//6. Types

function Routes() {
  return (
    <Suspense fallback={<Loader />}>
        <Switch>
          <Route element={<Prueba/>} path="/prueba"/>
          <Route element={<ReservaServicioYFecha/>} path="/reserva/elegir-reserva"/>
          <Route element={<Navigate to="/home"/>} path="/"/>
          <Route element={<LandingPage/>} path="/home"/>
          <Route element={<Sedes/>} path="/reserva/sedes"/>
          <Route element={<Servicios/>} path="/reserva/servicios"/>
          <Route element={<Login/>} path="/login"/>
          <Route element={<CompletarDatos/>} path="/completar-datos"/>
          <Route element={<Fecha/>} path="/reserva/fecha"/>
          <Route element={<Resumen/>} path="/reserva/resumen"/>
          <Route element={<Pago/>} path="/reserva/pago"/>
          <Route element={<Registro/>} path="/registro"/>
          <Route element={<Confirmacion/>} path="/reserva/confirmacion"/>
          <Route element={<Perfil/>} path="/editar-perfil"/>
          <Route element={<RecuperarContraseña/>} path="/recuperar-contrasena"/>
          <Route element={<CambiarContraseña/>} path="/cambiar-contrasena"/>
          <Route element={<VerificarCorreo/>} path="/verificar-correo"/>
          <Route element={<NotFound/>} path="/404"/>
          <Route element={<Home/>} path="/:slug"/>
          <Route element={<Home/>} path="*"/>
        </Switch>
    </Suspense>
  );
}

export default Routes;