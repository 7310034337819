// @ts-nocheck
import { combineReducers, createStore } from "redux";
import { saveState } from "../storage/localStorage";
import helpersReducer from "../reducers/helpersReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root2",
  storage,
  whitelist: ["helpers"],
};

const rootReducer = combineReducers({
  helpers: helpersReducer,
});

const newRootReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  newRootReducer,
  process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);


store.subscribe(() => {
  saveState(store.getState());
});