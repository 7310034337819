import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import ProfilePic from "../../Components/ProfilePic";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import check from "../../../assets/check-marker.svg";
import { instance, URL_REQ } from "../../../Providers/api";
import { SET_HELPERS } from "../../Redux/constants";
import axios from "axios";
import BackNavButton from "../../../Components/elements/Navigation/backNavButton";
import newServiceIcon from "../../../assets/new-service-icon.svg";
import newCalendarIcon from "../../../assets/new-calendar-icon.svg";
import newHourIcon from "../../../assets/new-hour-icon.svg";
import newTimerIcon from "../../../assets/new-timer-icon.svg";
import newPaymentIcon from "../../../assets/new-payment-icon.svg";
import location from "../../../assets/location-Icon.svg";
import powered from "../../../assets/powered-by-Viuty.svg";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section = styled.div<{ mobile?: boolean }>`
  background-color: #ffffff;
  font-family: "Mulish";
  width: 100%;
  max-width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  height: 74px;
  border-bottom: 1px solid #000000;
  box-sizing: border-box;
`;

const BodyContainer = styled.div<{ mobile?: boolean }>`
  padding: 16px 16px 0px 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  justify-content: center;
  .datos-0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    padding-right: ${(props) => (props.mobile ? "none" : "84px")};
  }
  .datos-1 {
    display: flex;
    flex-direction: column;
    padding-left: ${(props) => (props.mobile ? "none" : "84px")};
    width: ${(props) => (props.mobile ? "100%" : "319px")};
    border-left: ${(props) => (props.mobile ? "none" : "1px solid #77767A")};
  }
  .formatoValueSpace {
    margin-bottom: ${(props) => (props.mobile ? "12px" : "40px")};
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    p {
      font-size: 14px;
      font-weight: 400;
      padding: 0;
      margin: 0;
      width: 88%;
    }
    span {
      font-size: 18px;
      font-weight: 700;
      padding: 0;
      margin: 0;
      margin-left: 40px;
    }
  }
  .formatoValue {
    margin-bottom: ${(props) => (props.mobile ? "12px" : "40px")};
    min-height: 36px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    p {
      font-size: 14px;
      font-weight: 400;
      padding: 0;
      margin: 0;
    }

    i {
      font-size: 13px;
      font-weight: 400;
      padding: 0;
      margin: 0;
    }
    span {
      font-size: 18px;
      font-weight: 700;
      padding: 0;
      margin: 0;
    }
  }
  .imgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 23px;
      height: 23px;
    }
    width: 34px;
  }
  .imgPerfil {
    width: 23px;
    height: 23px;
    border-radius: 50%;
  }
  .imgSinPerfil {
    background: linear-gradient(270deg, #b5b5b5 0%, #d9d9d9 100%);
    width: 23px;
    height: 23px;
    border-radius: 50%;
  }
  .largeImage {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 23px;
      height: 23px;
      margin-right: 8px;
    }
    width: auto;
  }

  width: 100%;
`;

const Button = styled.button<{
  isDisable: any;
  widthButton?: string;
  heightButton?: string;
  mobile?: boolean;
}>`
  background-color: ${(props) =>
    props.disabled ? "#919094" : localStorage.getItem("color")};
  color: #ffffff;
  width: ${(props) => props.widthButton};
  height: ${(props) => (props.heightButton ? props.heightButton : "100%")};
  font-size: 16px;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Resumen = () => {
  const logo = localStorage.getItem("logo");
  const history = useNavigate();
  const helpers = useSelector((state: any) => state.helpers);
  const [coupon, setCoupon] = useState<any>("");
  const [couponSelected, setCouponSelected] = useState<any>({});
  const [valid, setValid] = useState(true);
  const dispatch = useDispatch();
  const [accept, setAccept] = useState(false);
  const [url, setUrl] = useState<any>("");

  const capitalize = (s: string) => {
    return s
      .split(" ")
      .filter((e) => e !== "" && e !== " ")
      .map((e: any) => e[0] + e.slice(1).toLowerCase())
      .join(" ");
  };

  useLayoutEffect(() => {
    instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
    dispatch({ type: SET_HELPERS, payload: { coupon: {}, couponString: "" } });
    //eslint-disable-next-line
  }, []);
  const ulrTerminosYCondiciones = async () => {
    const idLocal = helpers.locationSelected.id_sede;
    const bearer = helpers.bearer;
    const data = await axios({
      method: "GET",
      url: `${URL_REQ}api/v1/Sedes/terminosycondiciones/${idLocal}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    });
    const response = await data.data.data;
    setUrl(response === undefined ? "" : response);
  };

  const convertTime = (minutos: number | null | undefined): string => {
    if (minutos === null || minutos === undefined) {
      return "No se encontró un tiempo aproximado";
    }

    const horas: number = Math.floor(minutos / 60);
    const minutosRestantes: number = minutos % 60;

    if (horas > 0) {
      if (minutosRestantes > 0) {
        return `${horas}h y ${minutosRestantes}min`;
      } else {
        return `${horas}h`;
      }
    } else {
      return `${minutos}min`;
    }
  };

  useEffect(() => {
    ulrTerminosYCondiciones();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setValid(true);
    setCouponSelected({});
  }, [coupon]);

  const firstCapitalLetter = (texto: string) => {
    // Verificar si el valor es vacío, nulo o no es una cadena de texto
    if (!texto || typeof texto !== "string") {
      return ""; // Devolver cadena vacía en caso de valores no válidos
    }

    // Dividir el texto en palabras separadas por espacios
    var palabras = texto.trim().split(" ");

    // Capitalizar la primera letra de cada palabra y convertir el resto en minúsculas
    var palabrasFormateadas = palabras.map((palabra) => {
      return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
    });

    // Unir las palabras formateadas con espacios y devolver el resultado
    return palabrasFormateadas.join(" ");
  };

  return (
    <Container>
      {window.innerWidth >= 1440 ? (
        <TopBar>
          <div
            style={{ height: "74px", display: "flex", alignItems: "center" }}>
            <img
              src={logo?.toString() || ""}
              alt='logo'
              style={{ height: "45px", marginLeft: "45px", cursor: "pointer" }}
              onClick={() => {
                history(`/${helpers.slug}`);
              }}
            />
            <div style={{ position: "absolute", right: "20px" }}>
              <ProfilePic />
            </div>
          </div>
        </TopBar>
      ) : null}
      <Section>
        <div
          style={{
            height: `${window.innerWidth < 1440 ? "48px" : "80px"}`,
            paddingLeft: `${window.innerWidth < 1440 ? "16px" : "42px"}`,
            display: "flex",
          }}>
          <BackNavButton url={""} text={"Datos de tu cita"} />
        </div>
        <BodyContainer mobile={window.innerWidth < 1440}>
          <div className='datos-0'>
            {!helpers.serviceChosen.idPromocion ? (
              <div className='formatoValue'>
                <div className='imgContainer'>
                  <img src={newServiceIcon} alt='service' />
                </div>
                <div>
                  <p>Servicio</p>
                  <span>{capitalize(helpers.serviceChosen.nombre)}</span>
                </div>
              </div>
            ) : (
              <div className='formatoValue'>
                <div className='imgContainer'>
                  <img src={newServiceIcon} alt='service' />
                </div>
                <div>
                  <span>
                    {capitalize(helpers.serviceChosen.nombrePromocion)}
                  </span>
                </div>
              </div>
            )}
            <div className='formatoValue'>
              <div className='imgContainer'>
                <img src={newCalendarIcon} alt='' />
              </div>
              <div>
                <p>Fecha</p>
                <span>{`${("0" + helpers.day?.numero).slice(-2)}/${(
                  "0" + helpers.day?.mes
                ).slice(-2)}/${helpers.day?.año}`}</span>
              </div>
            </div>

            <div className='formatoValue'>
              <div className='imgContainer'>
                <img src={location} alt='' />
              </div>
              <div>
                <p>Ubicación</p>
                <span>
                  {firstCapitalLetter(helpers.locationSelected.nombre_sede)}
                </span>
                <br />
                <i>Dirección</i>
              </div>
            </div>

            <div style={{ display: "flex" }}></div>
            <div className='formatoValue'>
              <div className='imgContainer'>
                <img src={newHourIcon} alt='time' />
              </div>
              <div>
                <p>Hora</p>
                <span>{`${helpers.hour} hrs.`} </span>
              </div>
            </div>
            <div className='formatoValue'>
              <div className='imgContainer'>
                <div className='imgSinPerfil'></div>
              </div>
              <div>
                <p>Atendido por:</p>
                <span>{`Estilista: ${capitalize(
                  helpers.stylistSelected.nombreEmpleado
                )}`}</span>
              </div>
            </div>
            <div className='formatoValue'>
              <div className='imgContainer'>
                <img src={newTimerIcon} alt='time' />
              </div>
              <div>
                <p>Duracíon</p>
                {console.log(helpers)}
                <span>
                  {convertTime(helpers.serviceChosen.tiempoEnMinutos)}{" "}
                  aproximadamente
                </span>
              </div>
            </div>
          </div>
          <div className='datos-1'>
            {!helpers.serviceChosen.idPromocion ? (
              <div className='formatoValueSpace'>
                <div className='largeImage'>
                  <img src={newPaymentIcon} alt='payment' />
                  <p>Costo de servicio</p>
                </div>
                <div>
                  <span>
                    {!helpers.serviceChosen.idPromocion ? (
                      <>
                        {"S/. " +
                          helpers.serviceChosen.precio.toString() +
                          (helpers.serviceChosen.precio.toString().includes(".")
                            ? ""
                            : ".00")}
                      </>
                    ) : (
                      <>
                        {"S/. " +
                          helpers.serviceChosen.precioPromocion.toString() +
                          (helpers.serviceChosen.precioPromocion
                            .toString()
                            .includes(".")
                            ? ""
                            : ".00")}
                      </>
                    )}
                  </span>
                </div>
              </div>
            ) : (
              <div className='formatoValueSpace'>
                <div className='largeImage'>
                  <img src={newPaymentIcon} alt='payment' />
                  <p>Costo de servicio</p>
                </div>
                <div>
                  <span>
                    {!helpers.serviceChosen.idPromocion ? (
                      <>
                        {"S/" +
                          helpers.serviceChosen.precio.toString() +
                          (helpers.serviceChosen.precio.toString().includes(".")
                            ? ""
                            : ".00")}
                      </>
                    ) : (
                      <>
                        {"S/" +
                          helpers.serviceChosen.precioPromocion.toString() +
                          (helpers.serviceChosen.precioPromocion
                            .toString()
                            .includes(".")
                            ? ""
                            : ".00")}
                      </>
                    )}
                  </span>
                </div>
              </div>
            )}

            {helpers.locationSelected.tipoPago === 2 ? (
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "700",
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                }}>
                <span style={{ lineHeight: "35px" }}>
                  Pago parcial por adelantado:
                </span>
                <span style={{ marginLeft: "auto", lineHeight: "35px" }}>
                  {"S/" +
                    helpers.locationSelected.montoParcialPago.toString() +
                    (helpers.locationSelected.montoParcialPago
                      .toString()
                      .includes(".")
                      ? ""
                      : ".00")}
                </span>
              </div>
            ) : null}
            {helpers.serviceChosen.idservicio === -1 ||
            helpers.locationSelected.tipoPago === 2 ? (
              <p>
                Solicitamos un anticipo de
                {"S/" +
                  helpers.locationSelected.montoParcialPago.toString() +
                  (helpers.locationSelected.montoParcialPago
                    .toString()
                    .includes(".")
                    ? ""
                    : ".00")}
                para asegurar tu reserva. Va a cuenta del costo total de los
                servicios que te realices. Pagarás el saldo directamente en el
                local.
              </p>
            ) : null}
            {!helpers.serviceChosen.idPromocion &&
            helpers.serviceChosen.idservicio !== -1 ? (
              <>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    marginBottom: "5px",
                  }}>
                  Cupón de descuento
                </p>
                <div
                  style={{
                    display: "flex",
                    maxWidth: "100%",
                    position: "relative",
                  }}>
                  <input
                    type='text'
                    value={coupon}
                    placeholder='CUPÓN'
                    onChange={(e: any) =>
                      setCoupon(e.target.value.toLowerCase())
                    }
                    style={{
                      outline: "none",
                      border: !valid ? "1px solid red" : "1px solid #9e9e9e",

                      width: "100%",
                      height: "32px",
                      padding: "0px 15px",
                      borderRadius: "3px",
                      marginRight: "24px",
                    }}
                  />
                  <Button
                    widthButton={"85px"}
                    heightButton={"32px"}
                    mobile={window.innerWidth < 1440}
                    id='use-coupon'
                    disabled={coupon.length === 0}
                    //@ts-ignore
                    isDisabled={coupon.length === 0}
                    onClick={() => {
                      instance
                        .post("api/v1/CodigoPromocional/codigoPromocional", {
                          codigo: coupon,
                          fecha: `${helpers.day?.año}-${(
                            "0" + helpers.day?.mes
                          ).slice(-2)}-${("0" + helpers.day?.numero).slice(
                            -2
                          )}`,
                          hora: helpers.hour,
                          idEmpresa: helpers.companyId,
                          idSede: helpers.locationSelected.id_sede,
                          idServicio:
                            helpers.serviceChosen.idservicio ||
                            helpers.serviceChosen.idPromocion,
                        })
                        .then((res) => {
                          if (res.status === 200) {
                            setCouponSelected(res.data.data);
                            dispatch({
                              type: SET_HELPERS,
                              payload: {
                                coupon: { ...res.data.data },
                                couponString: coupon,
                              },
                            });
                          }
                        })
                        .catch(() => {
                          setCouponSelected({});
                          setValid(false);
                        });
                    }}>
                    Usar
                  </Button>
                  <img
                    src={check}
                    alt='ok'
                    style={{
                      position: "absolute",
                      right: "35%",
                      width: "20px",
                      top: "10px",
                      visibility: couponSelected.precioPromocion
                        ? "visible"
                        : "hidden",
                    }}
                  />
                </div>
                <span
                  style={{
                    fontSize: "12px",
                    color: "red",
                    visibility: valid ? "hidden" : "visible",
                  }}>
                  El cupón ingresado es incorrecto
                </span>
              </>
            ) : (
              <></>
            )}

            <div style={{ width: "85%", display: "flex", marginTop: "5px" }}>
              {!couponSelected?.precioPromocion ? (
                <></>
              ) : (
                <>
                  <span
                    style={{
                      color: "#424242",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}>
                    Descuento
                  </span>
                  <span
                    style={{
                      color: "#424242",
                      fontWeight: "700",
                      marginLeft: "auto",
                      fontSize: "18px",
                    }}>
                    {"- S/" +
                      couponSelected.montoDescuento.toString() +
                      (couponSelected.montoDescuento.toString().includes(".")
                        ? ""
                        : ".00")}
                  </span>
                </>
              )}
            </div>
            <div style={{ width: "85%", display: "flex", marginTop: "5px" }}>
              {!couponSelected?.precioPromocion ? (
                <></>
              ) : (
                <>
                  <span
                    style={{
                      color: "#424242",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}>
                    Precio final
                  </span>
                  <span
                    style={{
                      color: "#424242",
                      fontWeight: "700",
                      marginLeft: "auto",
                      fontSize: "18px",
                    }}>
                    {"S/" +
                      couponSelected.precioPromocion.toString() +
                      (couponSelected.precioPromocion.toString().includes(".")
                        ? ""
                        : ".00")}
                  </span>
                </>
              )}
            </div>
            {helpers.locationSelected.tipoPago === 2 ? (
              <div style={{ display: "flex", width: "85%" }}>
                <p style={{ margin: "0 0 0 auto", fontSize: "15px" }}>
                  Pagas
                  {"S/ " +
                    helpers.locationSelected.montoParcialPago.toString() +
                    (helpers.locationSelected.montoParcialPago
                      .toString()
                      .includes(".")
                      ? ""
                      : ".00")}
                  por adelantado
                </p>
              </div>
            ) : null}
            <div
              style={{
                width: "100%",
                margin: "24px 0px",
                display: "flex",
                color: "#77767A",
              }}>
              <div
                style={{
                  width: "24px",
                  marginRight: "8px",
                  display: "flex",
                  alignItems: "center",
                }}>
                <input
                  type='checkbox'
                  checked={accept}
                  onChange={(e) => setAccept(e.target.checked)}
                />
              </div>
              <div
                style={{
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                }}>
                <span>
                  Acepto los
                  <span
                    style={{
                      color: "" + localStorage.getItem("color"),
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}>
                    <a
                      href={url}
                      rel='noreferrer'
                      download={true}
                      target={"_blank"}>
                      términos y condiciones
                    </a>
                  </span>
                  que se establecen al momento de realizar una reserva.
                </span>
              </div>
            </div>

            <Button
              widthButton={window.innerWidth < 1440 ? "100%" : "319px"}
              heightButton={"40px"}
              disabled={!accept}
              mobile={window.innerWidth < 1440}
              //@ts-ignore
              isDisabled={!accept}
              //@ts-ignore
              onClick={() => {
                !helpers.user.nombre
                  ? history("/login")
                  : !helpers.user.dataDctoYNrTelf
                  ? history("/completar-datos")
                  : history("/reserva/pago");
              }}>
              {helpers.locationSelected.id_sede === 395  ? "Reservar" : "Ir a pagar"}
            </Button>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a href='https://viutify.com' target='_blank' rel='noreferrer'>
                <img
                  src={powered}
                  alt='powered by'
                  style={{
                    height: "16px",
                    marginTop: "42px",
                  }}
                />
              </a>
            </div>
          </div>
        </BodyContainer>
      </Section>
    </Container>
  );
};

export default Resumen;
