import React, { useState, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components';
import { instance } from '../../../Providers/api';
import { useDispatch, useSelector } from 'react-redux';
import BackNavButton from '../../../Components/elements/Navigation/backNavButton';
import visibility from '../../../assets/visibility-on-icon.svg';
import visibilityOff from '../../../assets/visibility-off-icon.svg';
import errorCheck from '../../../assets/error-check.svg';
import successCheck from '../../../assets/sucess-check.svg';
import backArrow from "../../../assets/back-arrow-icon.svg";
import peruFlag from "../../../assets/peru-flag.svg";
import dropdownArrow from "../../../assets/dropdown-arrow.svg";
import ButtonGoogleLogin from '../../../Components/elements/googleLogin/buttonGoogleLogin';
import Alert from '../../Components/Alert';
import { SET_HELPERS, SET_USER } from '../../Redux/constants';
import LoaderCircle from '../../../Components/elements/Loader/LoaderCircle';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
`;

const Section = styled.div`
    font-family: 'Mulish';
    width: 100%;
    max-width: 386px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin: auto;
    @media (max-width: 420px) {
        max-width: 328px;
    }
    
`;




const Registro = () => {
    const listCodArea = [
        { cod: "+51", pais: "Perú", img: peruFlag },
    ]
    const [codAreaSelected, setCodAreaSelected] = useState<any>(listCodArea[0]);

    const [logo, setLogo] = useState<any>();
    const dispatch = useDispatch();
    const helpers = useSelector((state: any) => state.helpers);
    const [datos, setDatos] = useState({
        documento: '',
        nombre: '',
        celular: '',
        fechaNac: '',
        correo: '',
        contrasena: '',
        confirmar: '',
        tipoDoc: 2,
        apellidos: '',
    });

    const [alert, setAlert] = useState(false);
    const [fail, setFail] = useState(false);
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [contador, setContador] = useState<any>();
    const [available, setAvailable] = useState(true);
    const [checkedAvail, setCheckedAvail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [botonOk, setBotonOk] = useState(false);
    const color = 'grey';


    useLayoutEffect(() => {
        instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (helpers.user.nombre) console.log("ya esta logueado");
        //eslint-disable-next-line
    }, [])
    useEffect(() => {
        localStorage.getItem('logo') && setLogo(localStorage.getItem('logo'));
    }, []);


    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setDatos((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (datos.contrasena !== datos.confirmar
            || !datos.correo || !datos.contrasena
            || !datos.confirmar) {
            setChecked1(true);
        }
    };

    const handleChangeConfirmacion = (event: any) => {
        const { name, value } = event.target;
        setDatos((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (!datos.documento || !datos.nombre
            || !datos.celular || !datos.fechaNac
            || !datos.tipoDoc
            || !datos.apellidos) {
            setChecked2(true);
        }
        else {
            if(datos.contrasena === datos.confirmar
                && datos.correo && datos.contrasena
                && datos.confirmar && datos.documento && datos.nombre
                && datos.celular && datos.fechaNac
                && datos.tipoDoc
                && datos.apellidos){
                    setBotonOk(true);
                }
        }
    }

    const handleRegistrar = () => {
        setLoading(true);

        instance.post('api/v1/NuevoUsuario/registrocorreo', {
            nombre: datos.nombre,
            apellidoPaterno: datos.apellidos,
            apellidoMaterno: ".",
            telefono: codAreaSelected.cod.substring(1) + datos.celular,
            correo: datos.correo,
            numero_documento: datos.documento,
            tipo_documento: datos.tipoDoc,
            clave: datos.contrasena,
            fechaNacimiento: datos.fechaNac,
        })
            .then(res => {
                if (res.data.status === 200) {
                    console.log("registrado");
                    handleLogin();
                } 
            })
            .catch((e) => { setFail(true); setLoading(false); console.log(e); });
    }

    const [siguiente, setSiguiente] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [openSelect, setOpenSelect] = useState(false);

    const goToTheNextForm = (value: boolean): void => {
        if (!available) return
        setSiguiente(value);
    };
    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };
    const handlerPassWordConfirmToggle = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };
    const openOrCloseSelect = () => {
        setOpenSelect(!openSelect);
    }
    const divRef = React.useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const [inputDateActive, setInputDateActive] = useState(false);

    useEffect(() => {
        if (divRef.current) {
            const divWidth = divRef.current.offsetWidth;
            setWidth(divWidth)
        }
    }, []);


   /* const history = useNavigate();

     useEffect(() => {

        if (success && loged) {
            if (Object.keys(helpers.serviceChosen).length === 0 ||
                Object.keys(helpers.day).length === 0 ||
                helpers.hour === ''
            ) {
                console.log("sedes");
                const redirectTimeout = setTimeout(() => {
                    history(`/reserva/sedes`);
                }, 3000);

                return () => {
                    clearTimeout(redirectTimeout);
                };

            } else {
                const redirectTimeout = setTimeout(() => {
                    history(`/reserva/resumen`);
                }, 3000);

                return () => {
                    clearTimeout(redirectTimeout);
                };
            }
        }
        //eslint-disable-next-line
    }, [success, history, helpers.slug]);
 */

    /*------------------- LOGUEARSE CON CORREO -------------------*/

    const handleLogin = async () => {
        try {
            console.log("login");
            await instance.post('api/v1/LoginUsuario/correo', {
                correo: datos.correo,
                contrasena: datos.contrasena,
            })
                .then(response => {
                    dispatch({
                        type: SET_HELPERS,
                        payload: {
                            bearer: response.data.data,
                        }
                    });
                    instance.defaults.headers.common.Authorization = `Bearer ${response.data.data}`;
                })
                .then(() => instance.get(
                    //'/datausuario'
                    'datausuario'
                ))
                .then(res => {
                    dispatch({
                        type: SET_USER,
                        payload: { ...res.data.data }
                    });
                    localStorage.setItem('image', '');
                })
                .then(() => { setLoading(false); setAlert(true); })
                .catch((e: any) => { setFail(true); console.log('error', e.message); });
        }
        catch (e: any) {
            console.log('error', e.message);
        }
    }
    /* {success ? <div style={{ backgroundColor: '#ffffff',height:'100vh'}}>
                    <CheckedSuccess>
                    <RegisterCheked fill={helpers.color} />
                    <p>¡Te has registrado en la aplicación de reservas de {helpers.company}!</p>
                    <span>Te estamos redigiriendo al centro de reservas.</span>
                    </CheckedSuccess>
                </div> :
                <div style={{ backgroundColor: '#ffffff'}}> */
    return (
        <>
            {loading ? <LoaderCircle color={helpers.color || color} pago={false} /> : null}
            {fail ? <Alert setShow={setFail} title='¡Oops! Ocurrió un error al iniciar sesión.' image={'fail'} borderColor={'#CA450D'} /> : null}
            {alert ? <Alert setShow={setAlert} title='¡Registrado con éxito!' image={'success'} backUrl={(Object.keys(helpers.serviceChosen).length === 0 || Object.keys(helpers.day).length === 0 || helpers.hour === '')?'/reserva/sedes':'/reserva/resumen'} borderColor={'#0DCA64'} /> : null}
            {
                !loading &&
                <Container>
                    <div style={{ paddingTop: '28px', paddingLeft: '36px' }}>
                        {siguiente ? (<BackToFirstForm onClick={() => goToTheNextForm(false)}> <img src={backArrow} alt="go-back" /> Regístrate</BackToFirstForm>) :
                            (<BackNavButton text='Regístrate' url={""} />)}
                    </div>
                    <Section ref={divRef}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {logo ? (<img src={logo || ''} alt='logo' referrerPolicy="no-referrer"
                                style={{
                                    objectFit: 'contain',
                                    maxHeight: '200px',
                                    maxWidth: '300px',
                                    marginBottom: '32px',
                                }}
                            />) : null}
                        </div>


                        {siguiente ? (<div>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <SelectDocument>
                                    <select name='tipoDoc' value={datos.tipoDoc} onChange={(e: any) => {
                                        handleChangeConfirmacion(e);
                                    }}>
                                        <option value={2}>DNI</option>
                                        <option value={1}>CE</option>
                                        <option value={0}>Otro</option>
                                    </select>
                                </SelectDocument>

                                <Input color={checked2 && !datos.documento.match(/^[0-9]{5,}$/) ? "#BA1A1A" : "#77767A"}>
                                    <input name='documento' placeholder='Número de documento' type='text' value={datos.documento} onChange={(e: any) => handleChangeConfirmacion(e)} />
                                    <div className='check'>
                                        {checked2 && !datos.documento.match(/^[0-9]{5,}$/) ?
                                            (<img src={errorCheck} alt="error-check" />) : (checked2 && datos.documento.match(/^[0-9]{5,}$/)) ? (<img src={successCheck} alt="success-check" />) : (null)}
                                    </div>
                                </Input>
                            </div>
                            <Message color={checked2 && !datos.documento.match(/^[0-9]{5,}$/) ? "#BA1A1A" : "#77767A"}>
                                {checked2 && !datos.documento.match(/^[0-9]{5,}$/) ? <p>Más de 5 números sin letras ni símbolos</p> : null}
                            </Message>

                            <Input color={(checked2 && !datos.nombre.match(/^[a-zA-ZáéíóúÁÉÍÓÚ ]{1,}$/)) ? "#BA1A1A" : "#77767A"}>
                                <input name='nombre' placeholder='Tu nombre' type='text' value={datos.nombre} onChange={(e: any) => handleChangeConfirmacion(e)} />
                                <div className='check'>
                                    {(checked2 && !datos.nombre.match(/^[a-zA-ZáéíóúÁÉÍÓÚ ]{1,}$/)) ?
                                        (<img src={errorCheck} alt="error-check" />) : (checked2 && datos.nombre.match(/^[a-zA-ZáéíóúÁÉÍÓÚ ]{1,}$/) ? (<img src={successCheck} alt="success-check" />) : (null))}
                                </div>
                            </Input>
                            <Message color={(checked2 && !datos.nombre.match(/^[a-zA-ZáéíóúÁÉÍÓÚ ]{1,}$/)) ? "#BA1A1A" : "#77767A"}>
                                {checked2 && !datos.nombre.match(/^[a-zA-ZáéíóúÁÉÍÓÚ ]{1,}$/) ? <p >No debe estar vacío, sin símbolos ni números</p> : null}
                            </Message>

                            <Input color={checked2 && !datos.apellidos.match(/^[a-zA-ZáéíóúÁÉÍÓÚ ]{1,}$/) ? "#BA1A1A" : "#77767A"}>
                                <input name='apellidos' placeholder='Tus apellidos' type="text" value={datos.apellidos} onChange={(e: any) => handleChangeConfirmacion(e)} />
                                <div className='check'>
                                    {checked2 && !datos.apellidos.match(/^[a-zA-ZáéíóúÁÉÍÓÚ ]{1,}$/) ?
                                        (<img src={errorCheck} alt="error-check" />) : (checked2 && datos.apellidos.match(/^[a-zA-ZáéíóúÁÉÍÓÚ ]{1,}$/) ?
                                            (<img src={successCheck} alt="success-check" />) : (null))}
                                </div>
                            </Input>
                            <Message color={checked2 && !datos.apellidos.match(/^[a-zA-ZáéíóúÁÉÍÓÚ ]{1,}$/) ? "#BA1A1A" : "#77767A"}>
                                {checked2 && !datos.apellidos.match(/^[a-zA-ZáéíóúÁÉÍÓÚ ]{1,}$/) ? <p>No debe estar vacío, sin símbolos ni números</p> : null}
                            </Message>

                            <InputNumber color={checked2 && !datos.celular.match(/^[0-9]{5,}$/) ? "#BA1A1A" : "#77767A"}>
                                <div>
                                    <div className='codArea' onClick={() => openOrCloseSelect()}>
                                        <div>
                                            <img src={codAreaSelected.img} alt={codAreaSelected.pais} />
                                        </div>
                                        <button> <img src={dropdownArrow} alt="open-dropDown" /></button>
                                        <div className='numberCod'>
                                            {codAreaSelected.cod}
                                        </div>
                                    </div>
                                    <div className={openSelect ? 'background' : 'nobackground'}>
                                        {listCodArea.map((item, index) => {
                                            return <div className='choise'
                                                key={index} onClick={() => setCodAreaSelected(item)}>
                                                <img src={item.img} alt={item.pais} />
                                                {item.cod}
                                            </div>
                                        })
                                        }
                                    </div>
                                </div>
                                <input name='celular' placeholder='' type='text' value={datos.celular} onChange={(e: any) => handleChangeConfirmacion(e)} />
                                <div className='check'>
                                    {checked2 && !datos.celular.match(/^[0-9]{5,}$/) ?
                                        (<img src={errorCheck} alt="error-check" />) : (checked2 && datos.celular.match(/^[0-9]{5,}$/) ?
                                            (<img src={successCheck} alt="success-check" />) : (null))}
                                </div>
                            </InputNumber>
                            <Message color={checked2 && !datos.celular.match(/^[0-9]{5,}$/) ? "#BA1A1A" : "#77767A"}>
                                {checked2 && !datos.celular.match(/^[0-9]{5,}$/) ? <p>Más de 5 números sin letras ni símbolos</p> : null}
                            </Message>

                            <InputDate color={checked2 && !datos.fechaNac ? "#BA1A1A" : "#77767A"}>

                                <input name='fechaNac' placeholder='Fecha de nacimiento' onClick={() => setInputDateActive(true)} type={inputDateActive ? 'date' : 'text'} value={datos.fechaNac} onChange={(e: any) => handleChangeConfirmacion(e)} />
                                {/* {checked2 && !datos.celular.match(/^[0-9]{5,}$/) ?
                                    (<img src={errorCheck} alt="error-check" />) : (checked2 && datos.celular.match(/^[0-9]{5,}$/) ?
                                        (<img src={successCheck} alt="success-check" />) : (null))} */}
                            </InputDate>
                            <Message color={checked2 && !datos.fechaNac ? "#BA1A1A" : "#77767A"}>
                                {checked2 && !datos.fechaNac ? <p>Fecha requerida</p> : null}
                            </Message>

                        </div>) : (<div>

                            <InputEmail color={(checkedAvail && !available) || (checked1 && !datos.correo.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) ? "#BA1A1A" : "#77767A"} >
                                <input name='correo' type='text' value={datos.correo}
                                    placeholder='Correo electrónico'
                                    onChange={(e: any) => {
                                        setAvailable(false);
                                        setCheckedAvail(false);
                                        if (contador) clearTimeout(contador);
                                        handleChange(e)
                                        if (e.target.value && e.target.value.match(/^[^\s@]{1,}@[^\s@]{1,}\.[^\s@]{1,}$/)) {
                                            //if(e.target.value === 'victor.falconi@lilab.pe') {
                                            setContador(setTimeout(() => instance.get(`/api/v1/NuevoUsuario/disponibilidadcorreo/${e.target.value}`)
                                                .then(_ => { setCheckedAvail(true); setAvailable(true) })
                                                .catch(err => {
                                                    console.log(err);
                                                    setCheckedAvail(true);
                                                    setAvailable(false);
                                                    console.log('ya existe email');
                                                }), 2000))
                                        }
                                    }}

                                />
                                <div className='check'>
                                    {(checkedAvail && !available) || (checked1 && !datos.correo.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) ?
                                        (<img src={errorCheck} alt="error-check" />) : (checkedAvail && available ? (<img src={successCheck} alt="success-check" />) : (null))}
                                </div>

                            </InputEmail>
                            <Message color={(checkedAvail && !available) || (checked1 && !datos.correo.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) ? "#BA1A1A" : "#006E2A"}>
                                {checkedAvail && (!available ? <p >*Ya existe una cuenta con este correo electrónico</p> : <p>El email está disponible</p>)}
                                {checked1 && !datos.correo.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? <p >*Formato de email incorrecto</p> : null}
                            </Message>

                            <InputPassword color={checked1 && datos.contrasena.length === 0 ? "#BA1A1A" : "#77767A"}>
                                <input name='contrasena' placeholder='Escribe tu contraseña' type={showPassword ? "text" : 'password'} value={datos.contrasena}
                                    onChange={(e: any) => { handleChange(e) }}
                                />
                                <button onClick={() => handlePasswordToggle()}>
                                    {showPassword ? <img src={visibility} alt="visibility" /> : <img src={visibilityOff} alt="visibilityOff" />}
                                </button>
                            </InputPassword>
                            <Message color={checked1 && datos.contrasena.length === 0 ? "#BA1A1A" : "#77767A"}>
                                {checked1 && datos.contrasena.length === 0 ? <p>Las contraseña no debe estar vacía</p> : null}
                            </Message>

                            <InputPassword color={checked1 && datos.contrasena !== datos.confirmar ? "#BA1A1A" : "#77767A"}>
                                <input name='confirmar' placeholder='Confirma tu contraseña' type={showPasswordConfirm ? "text" : 'password'} value={datos.confirmar} onChange={(e: any) => handleChange(e)}
                                />
                                <button onClick={() => handlerPassWordConfirmToggle()}>
                                    {showPasswordConfirm ? <img src={visibility} alt="visibility" /> : <img src={visibilityOff} alt="visibilityOff" />}
                                </button>
                            </InputPassword>
                            <Message color={checked1 && datos.contrasena !== datos.confirmar ? "#BA1A1A" : "#77767A"}>
                                {checked1 && datos.contrasena !== datos.confirmar ? <p>Las contraseñas no coinciden</p> : null}
                            </Message>

                        </div>)}

                        {!siguiente ? (<><Button color={helpers.color} onClick={() => { goToTheNextForm(true) }}>
                            Siguiente
                        </Button>
                            <ButtonGoogleLogin width={width}></ButtonGoogleLogin>
                        </>) : (<Button disabled={!botonOk} color={ !botonOk ? 'gray' : helpers.color} onClick={e => { e.preventDefault(); console.log(datos); handleRegistrar(); }}>
                            Confirmar
                        </Button>)}

                    </Section>
                </Container>
            }
        </>
    )
}

export default Registro;
/* const CheckedSuccess = styled.div`
margin: auto;
height: 100%;
width: fit-content;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
p{
max-width: 289px;
text-align: center;
font-size: 16px;
font-family: 'Mulish';
font-weight: 700;
}
span{
text-align: center;
font-size: 14px;
font-family: Mulish;
font-weight: 600; 
}
@media (max-width: 1439px){
    span{
        font-size: 12px;
    }
}

` */

const InputDate = styled.div<{ color: string }>`
border-radius: 4px;
border: 1px solid ${props => props.color};
height: 48px;
width: 100%;
display: flex;
justify-content: space-between;
padding:  0;
box-sizing: border-box;
.check{
    display: flex  ;
    justify-content: center;
    img{
        width: 20px;
    }
}
input{
    cursor: text;
    font-family: 'Mulish';
    background: none;
    outline: none;
    width: 100%;
    border: none;
    padding: 10px 12px 10px 12px;
    ::placeholder{
    color: #77767A;
    size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

`

const InputNumber = styled.div<{ color: string }>`
font-family: 'Mulish';
border-radius: 4px;
border: 1px solid ${props => props.color};
height: 48px;
width: 100%;
display: flex;
justify-content: space-between;
padding:  10px 12px 10px 12px;
box-sizing: border-box;
.check{
    display: flex  ;
    justify-content: center;
    img{
        width: 20px;
    }
}
.codArea{
    width: 80px;
    padding: 1px 6px 0px 6px;
    display: flex;
    font-size: 14px;
    background:none;
    align-items: center;
    justify-content:space-between;
    
    img{
        display: flex;
    }
    button{
        height: 24px;
        background: none;
        border: none;
        outline: none;
    }
    .numberCod{
        color: #9D9D9D;
    }
}
.background{
    background: #ffffff;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
    position: sticky;
    box-sizing: unset;
    .choise{display:flex;
        padding: 2px 6px 2px 6px;
    justify-content:space-between;
    font-size:12px;
    align-items:center;
    cursor:pointer;
    :hover{
        background: #e4e4e4;
    }
    }
}
.nobackground{
    background: none;
    display: none;
}
input{
    font-family: 'Mulish';
    background:none;
    outline: none;
    width: 100%;
    border: none;

    ::placeholder{
    color: #77767A;
    size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    }
}

`

const SelectDocument = styled.div`
select{
    border-radius: 4px;
    border: 1px solid #77767A;
    height: 48px;
    width: 75px;
    display: flex;
    justify-content: space-between;
    padding:  10px 12px 10px 12px;
    margin-right: 8px;
}
`

const BackToFirstForm = styled.button`
  cursor: pointer;
  background: none;
  width: 140px;
  font-family: 'Mulish';
  display: flex;
  border: none;
  font-size: 14px;
  align-items: center;
  color: #1B1B1F;
  gap: 10px;
  font-weight: 700;
  padding: 0;
  img{
    padding: 0;
    margin: 0;
  }

`


const Button = styled.button((props: any) => `
    background-color: ${props.color || ''};
    font-size: 14px;
    font-family: 'Mulish';
    font-weight: 600;
    color: #fff;
    width: 100%;
    height: 40px;

    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom:16px;
`);

const Message = styled.div<{ color: string }>`
height: 24px;
display: flex;
align-items : top;
p{  
    padding: 1px 0px 16px 12px;
    margin: 0;
    color: ${props => props.color};
    font-size: 12px;
    
}
`

const Input = styled.div<{ color: string }>`
border-radius: 4px;
border: 1px solid ${props => props.color};
height: 48px;
width: 100%;
display: flex;
justify-content: space-between;
padding:  10px 12px 10px 12px;
box-sizing: border-box;
.check{
    display: flex  ;
    justify-content: center;
    img{
        width: 20px;
    }
}
input{
    font-family: 'Mulish';
    background: none;
    outline: none;
    width: 100%;
    border: none;

    ::placeholder{
    color: #77767A;
    size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    }
}

`


const InputEmail = styled.div<{ color: string }>`
border-radius: 4px;
border: 1px solid ${props => props.color};
height: 48px;
width: 100%;
display: flex;
justify-content: space-between;
padding:  10px 12px 10px 12px;
box-sizing: border-box;
.check{
    display: flex  ;
    justify-content: center;
    img{
        width: 20px;
    }
}
input{
    background: none;
    outline: none;
    width: 100%;
    border: none;

    ::placeholder{
    color: #77767A;
    size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    }
}


`



const InputPassword = styled.div<{ color: string }>`
border-radius: 4px;
border: 1px solid ${props => props.color};
height: 48px;
width: 100%;
display: flex;
justify-content: space-between;
padding:  10px 12px 10px 12px;
box-sizing: border-box;


input{
    
    background: none;
    outline: none;
    width: 100%;
    border: none;

    ::placeholder{
    color: #77767A;
    size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    
    
}

}
button{
    border: none;
    outline: none;
    display: flex;
    padding: 0;
    background: none;
    
    img{
        width: 22px;
        align-self: center;
         
    }
}

`;