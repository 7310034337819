import React, { useState, useEffect, useLayoutEffect } from "react";
import backArrow from "../../../assets/back-arrow-icon.svg";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import leftArrowDate from "../../../assets/left-arrow-date.svg";
import { SET_HELPERS } from "../../Redux/constants";
import { useNavigate } from "react-router-dom";
import { instance } from "../../../Providers/api";
import cajaDeServicio from "../../../assets/caja-de-servicio.svg";
import newArrowDropdown from "../../../assets/new-arrow-dropDown.svg";

interface Vista {
  width: number;
  changeVista: any;
  loading?: boolean;
}

const ElegirFecha: React.FC<Vista> = ({ width, changeVista, loading }) => {
  const history = useNavigate();
  const [dateList, setDateList] = useState<any>([]);
  const [stylists, setStylists] = useState<any>();
  const [stylistSelected, setStylistSelected] = useState<any>();
  const helpers = useSelector((state: any) => state.helpers);
  const [day, setDay] = useState<any>({});
  const [hours, setHours] = useState<any>([]);

  const dispatch = useDispatch();
  const now = new Date().toLocaleTimeString();
  const today = new Date();
  const [intervalsLeft, setIntervalsLeft] = useState<any>([]);
  const [intervalsRight, setIntervalsRight] = useState<any>([]);
  const [intervalIdLeft, setIntervalIdLeft] = useState<any>();
  const [intervalIdRight, setIntervalIdRight] = useState<any>();
  const color = localStorage.getItem("color");

  const toDayName = (n: number) => {
    switch (n) {
      case 0:
        return "Domingo";
      case 1:
        return "Lunes";
      case 2:
        return "Martes";
      case 3:
        return "Miercoles";
      case 4:
        return "Jueves";
      case 5:
        return "Viernes";
      case 6:
        return "Sabado";
    }
  };

  const toMonthName = (n: number) => {
    switch (n) {
      case 1:
        return "ENE";
      case 2:
        return "FEB";
      case 3:
        return "MAR";
      case 4:
        return "ABR";
      case 5:
        return "MAY";
      case 6:
        return "JUN";
      case 7:
        return "JUL";
      case 8:
        return "AGO";
      case 9:
        return "SEP";
      case 10:
        return "OCT";
      case 11:
        return "NOV";
      case 12:
        return "DIC";
    }
  };

  const generateDateList = () => {
    var date = new Date();
    var newDateList = [];
    for (var i = 0; i < 90; i++) {
      newDateList.push({
        numero: date.getDate(),
        dia: toDayName(date.getDay()),
        mes: date.getMonth() + 1,
        año: date.getFullYear(),
      });
      date.setDate(date.getDate() + 1);
    }
    return newDateList;
  };

  const autoScroll = (direction: string) => {
    if (direction === "left") {
      setIntervalIdLeft(
        setInterval(() => {
          document.getElementById("scroll")?.scrollBy(-3, 0);
        }, 5)
      );
      setIntervalsLeft([...intervalsLeft, intervalIdLeft]);
    } else {
      setIntervalIdRight(
        setInterval(() => {
          document.getElementById("scroll")?.scrollBy(3, 0);
        }, 5)
      );
      setIntervalsRight([...intervalsRight, intervalIdRight]);
    }
  };

  const toComparableNumber = (s: string) => {
    var a = s.split(":");
    return parseFloat(a[0]) + parseFloat(a[1]) / 60;

  };
  useEffect(() => {
    console.log(helpers)
  }, [helpers]);

  useLayoutEffect(() => {
    instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
    const newDateList = generateDateList();
    setDateList(newDateList);
    const enabledDates = newDateList.filter(
      (d: any) =>
        !helpers.locationSelected.fechas_no_atencion.includes(
          `${d.año}-${("0" + d.mes).slice(-2)}-${("0" + d.numero).slice(-2)}`
        )
    );
    setDay(helpers.day || enabledDates[0]);
    instance
      .post("api/v1/Estilistas/listarestilistasporservicio", {
        idempresa: helpers.companyId,
        idlocal: helpers.locationSelected.id_sede,
        idservicio: helpers.serviceChosen.idservicio,
      })
      .then((res: any) => {
        setStylists([
          { idEmpleado: -1, nombreEmpleado: helpers.locationSelected.id_sede === 395   ? "Ejila un masajista" : 'Cualquier miembro del equipo' },
          ...res.data.data,
        ]);
        setStylistSelected(
          helpers.stylistSelected || {
            idEmpleado: -1,
            nombreEmpleado: helpers.locationSelected.id_sede === 395   ? "Ejila un masajista" : 'Cualquier miembro del equipo',
          }
        );
      })
      .then(() => {
        if (helpers.serviceChosen?.idPromocion) {
          if (day?.año) {
            return instance.get(
              `api/v1/Promociones/listarHorariosDisponibles?idPromocion=${helpers.serviceChosen.idPromocion
              }&fecha=${day?.año}-${("0" + day?.mes).slice(-2)}-${(
                "0" + day?.numero
              ).slice(-2)}&idLocal=${helpers.locationSelected.id_sede}`
            );
          } else return;
        } else {
          if (day?.año) {
            return instance.post(
              "api/v1/Estilistas/horariosdisponibilidad/estilista",
              {
                id_empresa: helpers.companyId,
                id_sede: helpers.locationSelected.id_sede,
                id_servicio: helpers.serviceChosen.idservicio,
                fecha: `${day?.año}-${("0" + day?.mes).slice(-2)}-${(
                  "0" + day?.numero
                ).slice(-2)}`,
                id_empleado: stylistSelected?.idEmpleado,
              }
            );
          } else return;
        }
      })
      .then((res: any) => {
        if (stylistSelected?.idEmpleado !== -1 && day?.año) {
          setHours(res.data.data.horarios);
        }
      });
    //eslint-disable-next-line
  }, [helpers.serviceChosen]);

  useEffect(() => {
    if (helpers.serviceChosen?.idPromocion) {
      if (day?.año && stylistSelected?.idEmpleado) {
        if (day?.año) {
          instance
            .get(
              `api/v1/Promociones/listarHorariosDisponibles?idPromocion=${helpers.serviceChosen.idPromocion
              }&fecha=${day?.año}-${("0" + day?.mes).slice(-2)}-${(
                "0" + day?.numero
              ).slice(-2)}&idLocal=${helpers.locationSelected.id_sede}`
            )
            .then((res: any) => setHours(res.data.data));
        }
      }
    } else {
      if (day?.año && stylistSelected?.idEmpleado) {
        instance
          .post("api/v1/Estilistas/horariosdisponibilidad/estilista", {
            id_empresa: helpers.companyId,
            id_sede: helpers.locationSelected.id_sede,
            id_servicio: helpers.serviceChosen.idservicio,
            fecha: `${day?.año}-${("0" + day?.mes).slice(-2)}-${(
              "0" + day?.numero
            ).slice(-2)}`,
            id_empleado: stylistSelected?.idEmpleado,
          })
          .then((res: any) => setHours(res.data.data));
      }
    }
    //eslint-disable-next-line
  }, [day, stylistSelected]);





  const clearRunningIntervals = () => {
    intervalsLeft.map((i: any) => {
      clearInterval(i);
      return null;
    });
    intervalsRight.map((i: any) => {
      clearInterval(i);
      return null;
    });
  };

  const [sinEstilistas, setSinEstilistas] = useState(false);

  const confirmar = (hour: any) => {

    if (stylistSelected?.idEmpleado !== -1) {
      if (helpers.locationSelected.id_sede === 395  ) setSinEstilistas(false);

      dispatch({
        type: SET_HELPERS,
        payload: { day, hour: hour, stylistSelected },
      });
      history("/reserva/resumen");
    } else {
      if (helpers.locationSelected.id_sede === 395  ) {
        setSinEstilistas(true);
      } else {
        dispatch({
          type: SET_HELPERS,
          payload: { day, hour: hour, stylistSelected },
        });
        history("/reserva/resumen");
      }
    }
  };

  const [openSelect, setOpenSelect] = useState(false);
  const openOrCloseSelect = () => {
    setOpenSelect(!openSelect);
  }

  return (
    <>
      {!helpers.serviceChosen.idservicio && !helpers.serviceChosen.idPromocion ? (
        !loading ? (
          <Container width={width}>
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}>
                <div
                  style={{
                    backgroundColor: `${color || "white"}`,
                    width: "156px",
                    height: "156px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "12px",
                  }}>
                  <img
                    src={cajaDeServicio}
                    alt='elegir-servicio'
                    width='76px'
                  />
                </div>
                <p
                  style={{
                    color: "#1B1B1F",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}>
                  Selecciona un servicio o promoción
                </p>
                <span style={{ fontSize: "14px" }}>
                  Selecciona un servicio para continuar con{" "}
                </span>
                <span style={{ fontSize: "14px" }}>la reserva.</span>
              </div>
            </div>
          </Container>
        ) : (
          <Container width={width}>
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}>
                <LoaderCard height='313px' width='336px'>
                  <div className='space'></div>
                </LoaderCard>
              </div>
            </div>
          </Container>
        )
      ) : (
        <Container width={width}>
          <NavigationService>
            {width < 1440 ? (
              <BackButton onClick={() => changeVista()}>
                <img src={backArrow} alt='go-to-reserva' />
                Reservar
              </BackButton>
            ) : null}
            <div></div>
          </NavigationService>
          <p style={{ fontSize: "12px", fontWeight: "700" }}>
            Selecciona quién te atenderá
          </p>

          <div>
            {!helpers.serviceChosen.idPromocion &&
              helpers.serviceChosen.idservicio !== -1 &&
              helpers.locationSelected.seleccionEstilista ? (
              <Select>
                <div className='codArea' onClick={() => openOrCloseSelect()}>
                  <div className='select'>
                    <div className='imgSinPerfil'></div>
                    <p style={{ width: "80%" }}>
                      {stylistSelected?.nombreEmpleado.toString()}{" "}
                    </p>
                  </div>
                  <div>
                    <img src={newArrowDropdown} alt='select' />
                  </div>
                </div>
                <div className={openSelect ? "background" : "nobackground"}>
                  {stylists?.map((item: any, index: number) => {
                    return (
                      <div
                        className='choise'
                        key={index}
                        onClick={() => {
                          setStylistSelected(item);
                          setSinEstilistas(false);
                          openOrCloseSelect();
                        }}>
                        <div className='imgSinPerfil'> </div>
                        <p style={{ width: "70%" }}>{item.nombreEmpleado}</p>
                      </div>
                    );
                  })}
                </div>
              </Select>
            ) : null}
          </div>
          {sinEstilistas ? (<p style={{ fontSize: "12px", fontWeight: "700", color: "red", padding: "0", margin: "0" }}>
            {helpers.locationSelected.id_sede === 395   ? "No ha seleccionado masajista" : "No ha seleccionado estilista"}
          </p>) : null}
          <p style={{ color: "#1B1B1F", fontSize: "12px", fontWeight: "700" }}>
            Selecciona la fecha
          </p>
          <div
            style={{
              width: "100%",
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              borderTop: "1px solid #77767A",
              borderBottom: "1px solid #77767A",
            }}>
            {window.innerWidth > 600 ? (
              <img
                src={leftArrowDate}
                alt='left'
                style={{
                  position: "absolute",
                  left: "15px",
                  height: "15px",
                  cursor: "pointer",
                }}
                id='scroll-left'
                onMouseDown={() => autoScroll("left")}
                onMouseUp={() => {
                  clearInterval(intervalIdLeft);
                  clearRunningIntervals();
                }}
                onDrag={() => {
                  clearInterval(intervalIdLeft);
                }}
              />
            ) : null}
            <DateContainer id='scroll'>
              {dateList.map((d: any, i: any) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "800",
                  }}>
                  <span
                    style={{
                      fontSize: "13px",
                      lineHeight: "40px",
                      color:
                        helpers.locationSelected.fechas_no_atencion.includes(
                          `${d.año}-${("0" + d.mes).slice(-2)}-${(
                            "0" + d.numero
                          ).slice(-2)}`
                        )
                          ? "#9e9e9e"
                          : "inherit",
                    }}>
                    {d.dia[0]}
                  </span>
                  <div
                    style={{
                      width: "40px",
                      margin: "0",
                      padding: "0",
                      backgroundColor: "transparent",
                      height: "0",
                      border:
                        "1px solid " +
                        (helpers.locationSelected.fechas_no_atencion.includes(
                          `${d.año}-${("0" + d.mes).slice(-2)}-${(
                            "0" + d.numero
                          ).slice(-2)}`
                        )
                          ? "#9e9e9e"
                          : "#727070"),
                    }}
                  />
                  <div
                    onClick={() => {
                      if (
                        !helpers.locationSelected.fechas_no_atencion.includes(
                          `${d.año}-${("0" + d.mes).slice(-2)}-${(
                            "0" + d.numero
                          ).slice(-2)}`
                        )
                      )
                        setDay(d);
                    }}
                    style={{
                      borderRadius: "50%",
                      backgroundColor:
                        day.numero === d.numero &&
                          day.mes === d.mes &&
                          day.año === d.año
                          ? `${color || "white"}`
                          : "transparent",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      margin: "9px 0 4px",
                    }}>
                    <span
                      style={{
                        color:
                          helpers.locationSelected.fechas_no_atencion.includes(
                            `${d.año}-${("0" + d.mes).slice(-2)}-${(
                              "0" + d.numero
                            ).slice(-2)}`
                          )
                            ? "#9e9e9e"
                            : day.numero === d.numero &&
                              day.mes === d.mes &&
                              day.año === d.año
                              ? "#ffffff"
                              : "#727070",
                        fontSize: "13px",
                        lineHeight: "40px",
                      }}>
                      {("0" + d.numero).slice(-2)}
                    </span>
                  </div>
                  <span
                    style={{
                      fontSize: "13px",
                      fontWeight:
                        day.numero === d.numero &&
                          day.mes === d.mes &&
                          day.año === d.año
                          ? "800"
                          : "400",
                      visibility:
                        d.numero === 1 ||
                          i === 0 ||
                          (day.numero === d.numero &&
                            day.mes === d.mes &&
                            day.año === d.año)
                          ? "visible"
                          : "hidden",
                    }}>
                    {toMonthName(d.mes)}
                  </span>
                </div>
              ))}
            </DateContainer>
            {window.innerWidth > 600 ? (
              <img
                src={leftArrowDate}
                alt='right'
                style={{
                  position: "absolute",
                  right: "15px",
                  height: "15px",
                  transform: "rotate(180deg)",
                  cursor: "pointer",
                }}
                onMouseDown={() => autoScroll("right")}
                onMouseUp={() => {
                  clearInterval(intervalIdRight);
                  clearRunningIntervals();
                }}
                onDrag={() => {
                  clearInterval(intervalIdRight);
                }}
              />
            ) : null}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{ color: "#1B1B1F", fontSize: "12px", fontWeight: "700" }}>
              Selecciona la hora
            </p>
            <Horarios color={color}>
              {hours?.filter(
                (h: any) =>
                  toComparableNumber(h) >=
                  toComparableNumber(
                    helpers.locationSelected.hora_apertura
                  ) &&
                  (today.getDate() === day.numero &&
                    today.getMonth() === day.mes - 1 &&
                    today.getFullYear() === day.año
                    ? toComparableNumber(h) >= toComparableNumber(now)
                    : true) &&
                  toComparableNumber(h) <=
                  toComparableNumber(helpers.locationSelected.hora_cierre) - 1
              ).length > 0 ? (
                <>
                  <p
                    style={{
                      color: "#1B1B1F",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}>
                    Mañana
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}>
                    {hours?.filter(
                      (h: any) =>
                        toComparableNumber(h) < 12 &&
                        toComparableNumber(h) >=
                        toComparableNumber(
                          helpers.locationSelected.hora_apertura
                        ) &&
                        (today.getDate() === day.numero &&
                          today.getMonth() === day.mes - 1 &&
                          today.getFullYear() === day.año
                          ? toComparableNumber(h) >=
                          toComparableNumber(now)
                          : true)
                    ).length > 0 ? (
                      hours
                        ?.filter(
                          (h: any) =>
                            toComparableNumber(h) < 12 &&
                            toComparableNumber(h) >=
                            toComparableNumber(
                              helpers.locationSelected.hora_apertura
                            ) &&
                            (today.getDate() === day.numero &&
                              today.getMonth() === day.mes - 1 &&
                              today.getFullYear() === day.año
                              ? toComparableNumber(h) >=
                              toComparableNumber(now)
                              : true)
                        )
                        .map((h: any, i: any) => (
                          <div
                            key={i}
                            onClick={() => {

                              confirmar(h);
                            }}
                            className="hourHover"
                          >
                            {h}
                          </div>
                        ))
                    ) : (
                      <p
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontSize: "14px",
                        }}>
                        No existen horarios de mañana
                        <br />
                        en la fecha seleccionada
                      </p>
                    )}
                  </div>
                  <p
                    style={{
                      color: "#1B1B1F",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}>
                    Tarde
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}>
                    {hours?.filter(
                      (h: any) =>
                        toComparableNumber(h) >= 12 &&
                        toComparableNumber(h) < 19 &&
                        (today.getDate() === day.numero &&
                          today.getMonth() === day.mes - 1 &&
                          today.getFullYear() === day.año
                          ? toComparableNumber(h) >=
                          toComparableNumber(now)
                          : true) &&
                        toComparableNumber(h) <=
                        toComparableNumber(
                          helpers.locationSelected.hora_cierre
                        ) -
                        1
                    ).length > 0 ? (
                      hours
                        ?.filter(
                          (h: any) =>
                            toComparableNumber(h) >= 12 &&
                            toComparableNumber(h) < 19 &&
                            (today.getDate() === day.numero &&
                              today.getMonth() === day.mes - 1 &&
                              today.getFullYear() === day.año
                              ? toComparableNumber(h) >=
                              toComparableNumber(now)
                              : true) &&
                            toComparableNumber(h) <=
                            toComparableNumber(
                              helpers.locationSelected.hora_cierre
                            ) -
                            1
                        )
                        .map((h: any, i: any) => (
                          <div
                            key={i}
                            onClick={() => {

                              confirmar(h);
                            }}
                            className="hourHover"
                          >
                            {h}
                          </div>
                        ))
                    ) : (
                      <p
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontSize: "14px",
                        }}>
                        No existen horarios de tarde
                        <br />
                        en la fecha seleccionada
                      </p>
                    )}
                  </div>
                  <p
                    style={{
                      color: "#1B1B1F",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}>
                    Noche
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}>
                    {hours?.filter(
                      (h: any) =>
                        toComparableNumber(h) >= 19 &&
                        (today.getDate() === day.numero &&
                          today.getMonth() === day.mes - 1 &&
                          today.getFullYear() === day.año
                          ? toComparableNumber(h) >=
                          toComparableNumber(now)
                          : true) &&
                        toComparableNumber(h) <=
                        toComparableNumber(
                          helpers.locationSelected.hora_cierre
                        ) -
                        1
                    ).length > 0 ? (
                      hours
                        ?.filter(
                          (h: any) =>
                            toComparableNumber(h) >= 19 &&
                            (today.getDate() === day.numero &&
                              today.getMonth() === day.mes - 1 &&
                              today.getFullYear() === day.año
                              ? toComparableNumber(h) >=
                              toComparableNumber(now)
                              : true) &&
                            toComparableNumber(h) <=
                            toComparableNumber(
                              helpers.locationSelected.hora_cierre
                            ) -
                            1
                        )
                        .map((h: any, i: any) => (
                          <div
                            key={i}
                            onClick={() => {

                              confirmar(h);
                            }}
                            className="hourHover"
                          >
                            {h}
                          </div>
                        ))
                    ) : (
                      <p
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontSize: "14px",
                        }}>
                        No existen horarios de noche
                        <br />
                        en la fecha seleccionada
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "14px",
                  }}>
                  No existen horarios en la fecha seleccionada.
                  <br />
                  Por favor,
                  <br /> seleccione otro estilista u otra fecha.
                </p>
              )}
            </Horarios>
          </div>
        </Container>
      )}
    </>
  );
};

export default ElegirFecha;

const LoaderCard = styled.div<{ height: string; width: string }>`
  .space {
    height: ${(props) => props.height};
  }
  width: ${(props) => props.width};
  border-radius: 4px;
  height: ${(props) => props.height};
  margin-bottom: 16px;

  background: #eee;
  background: linear-gradient(90deg, #cdcdcd 8%, #e9e9e9 30%, #cdcdcd 48%);
  background-size: 200% 100%;
  animation: 1.2s shine linear infinite;
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

const Select = styled.div`
  font-family: "Mulish";
  position: relative;
  cursor: pointer;

  .codArea {
    width: 95.706%;
    padding: 0px 22px 0px 26px;
    display: flex;
    font-size: 12px;
    align-items: center;
    gap: 12px;
    border: 1px solid #727070;
    box-sizing: border-box;
    border-radius: 4px;
    height: 66px;
    justify-content: space-between;
    .select {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .imgPerfil {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
  }
  .imgSinPerfil {
    background: linear-gradient(270deg, #b5b5b5 0%, #d9d9d9 100%);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
  }
  .background {
    z-index: 1;
    margin-top: 2px;
    width: 95.706%;
    background: #ffffff;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    display: grid;
    gap: 12px;
    padding: 8px 0px;
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;

    .choise {
      display: flex;
      padding: 0px 14px;
      justify-content: start;
      font-size: 12px;
      gap: 18px;
      align-items: center;
      height: 48px;
      cursor: pointer;
      width: 102.801%;
    }
  }
  .nobackground {
    background: none;
    display: none;
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  background: none;
  font-family: "Mulish";
  display: flex;
  border: none;
  font-size: 14px;
  align-items: center;
  color: #1b1b1f;
  gap: 10px;
  font-weight: 700;
  padding: 0;

  img {
    padding: 0;
    margin: 0;
  }
`;

const Horarios = styled.div<{ color?: any }>`

.hourHover{
                              border: 1px solid #727070;
                              padding: 10px;
                              border-radius: 5px;
                              margin: 0px 10px 10px 1px;
                              background-color:white;
                              color: #727070;
                              cursor: pointer;
                              box-sizing: border-box;
   :hover{
    box-sizing: border-box;
    border: 1px solid ${(props) => props.color};
    outline: 1px solid ${(props) => props.color};
   } 
  }
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 46vh;
`;

const DateContainer = styled.div`
  height: 130px;
  color: #727070;
  display: flex;
  justify-content: left;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 80%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled.div<{ width: number }>`
  
  position: relative;
  box-sizing: border-box;
  padding: 16px 16px 0px 16px;
  background-color: #ffffff;
  width: ${(props) => (props.width < 1440 ? "100%" : "684px")};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const NavigationService = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
