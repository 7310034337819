import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { instance } from '../../../Providers/api';
import { useDispatch, useSelector } from 'react-redux';
import { SET_HELPERS } from '../../Redux/constants';
import LoaderCircle from '../../../Components/elements/Loader/LoaderCircle';
import {URL_FRONT} from "../../../Providers/api";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #F2F2F2;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    overflow-y: scroll;
    color: #727070;
`;

// const Section = styled.div`
//     width: 100%;
//     max-width: 600px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     background-color: #e7e7e7;
//     position: relative;
// `;

// const TopBar = styled.div`
//     display: flex;
//     width: 100%;
//     position: relative;
//     margin-top: 15px;
// `;

// const Navigation = styled.div`
//     display: flex;
//     width: 100%;
//     margin-top: 15px;
// `;

// const Title = styled.span`
//     font-size: 20px;
//     margin-left: 15px;
//     font-weight: 800;
// `;

// const BodyContainer = styled.div`
//     background-color: #ffffff;
//     border-radius: 20px 20px 0 0;
//     display: flex;
//     padding-top: 20px;
//     width: 100%;
//     justify-content: center;
//     flex-direction: column;
//     align-items: center;
//     min-height: calc(100vh - 160px);
//     margin-top: 30px;
// `;

const Pago = () => {

    const history = useNavigate();
    //const logo = localStorage.getItem('logo');
    const helpers = useSelector((state: any) => state.helpers);
    const [redirect, setRedirect] = useState('');
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`;
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const montoPrepagoReserva = ()=>{
            const montoParcialPago =  helpers.locationSelected.montoParcialPago
            const precioPromocion =  helpers.coupon?.precioPromocion 
            const precioRegular = helpers.serviceChosen.precio
            if(montoParcialPago){
                if(precioPromocion <= montoParcialPago){
                    return precioPromocion
                }
                else if(precioRegular <= montoParcialPago){
                    return precioRegular
                }
                else return montoParcialPago
            }
            else if(precioPromocion){
                return precioPromocion
            }else{
                return precioRegular
            }
        }
        !helpers.serviceChosen.idPromocion ?
        instance.post('api/v1/Reservas', {
            idPromocion: helpers.coupon?.idPromocion || 0,
            idempresa: helpers.companyId,
            idsede: helpers.locationSelected.id_sede,
            fecha: `${helpers.day?.año}-${('0' + helpers.day?.mes).slice(-2)}-${('0' + helpers.day?.numero).slice(-2)}`,
            hora: helpers.hour,
            data: [
                {
                    idservicio: helpers.serviceChosen.idservicio,
                    nombre: helpers.serviceChosen.idservicio === -1 ? "" : helpers.serviceChosen.nombre,
                    precio: helpers.serviceChosen.idservicio === -1 ? 0 : helpers.serviceChosen.precio,
                    idestilista: helpers.stylistSelected.idEmpleado,
                    tipodescuento: helpers.coupon?.tipoDescuento || 0,
                    porcentajeDescuento: helpers.coupon?.descuento || 0,
                    precioPromocion: helpers.coupon?.precioPromocion || 0,
                }
            ],
            montoPrepagadoReserva:montoPrepagoReserva(),
        })
        .then((res) => {
            if( helpers.locationSelected.id_sede === 395 ){
                instance.post(`/api/v1/Reservas/reservaGratuita?idReserva=${res.data.idreserva}`)         
                setRedirect(`${URL_FRONT}reserva/confirmacion?message=Reserva%20Pagada%20exitosamente&code=200`);
                document.getElementById('pagar')?.click();             
                return;
            }
            dispatch({type: SET_HELPERS, payload: {reservationId: res.data.idreserva}})
            return instance.post(`api/v1/PasarelaPago/pagos/${res.data.idreserva}/${helpers.serviceChosen.nombre}`)
        })
        .then((res) => {
            if(res ===  undefined) return;
            setRedirect(res.data.data.initPoint);
            document.getElementById('pagar')?.click();
        })
        .catch((e: any) => {console.log(e.message); history('/reserva/confirmacion')})
        : instance.post('api/v1/Reservas/reservapromocion', {
            idempresa: helpers.companyId,
            idsede: helpers.locationSelected.id_sede,
            idPromocion: helpers.serviceChosen.idPromocion,
            precioPromocion: helpers.serviceChosen.precioPromocion,
            fecha: `${helpers.day?.año}-${('0' + helpers.day?.mes).slice(-2)}-${('0' + helpers.day?.numero).slice(-2)}`,
            horaInicio: helpers.hour,
            data: [...helpers.serviceChosen.serviciosPromocion],
            montoPrepagadoReserva: helpers.locationSelected.montoParcialPago || helpers.serviceChosen.precioPromocion,
        })
        .then(res => {
            dispatch({type: SET_HELPERS, payload: {reservationId: res.data.idreserva}});
            return instance.post(`api/v1/PasarelaPago/pagos/${res.data.idreserva}/${helpers.serviceChosen.nombrePromocion}`)
        })
        .then(res => {
            setRedirect(res.data.data.initPoint);
            document.getElementById('pagar')?.click();
        })
        .catch((e: any) => {console.log(e.message); history('/reserva/confirmacion')})
    //eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     //redirect && window.open(redirect);
    // }, [redirect]);
    console.log(redirect)
    return (
        <Container>
            {!redirect?
            <LoaderCircle color={helpers.color || 'gray'} pago={true}/>:
            <a id='pagar' href={redirect} style={{visibility: 'hidden'}}>Pagar</a>}
        </Container>
    )
}

export default Pago;



// <Section>
// <TopBar>
//     <img src={logo?.toString() || ''} alt='logo' style={{height: '30px', marginLeft: '40px'}} onClick={() => {history(`/${helpers.slug}`)}}/>
//     <div style={{position: 'absolute', right: '20px'}}><ProfilePic/></div>
// </TopBar>
// <Navigation>
//     <img src={leftArrow} style={{width: '10px', marginLeft: '15px', visibility: 'hidden'}} alt='back'/>
//     <Title>
//         Su pago esta siendo<br/>
//         procesado
//     </Title>
// </Navigation>
// <BodyContainer>
//     <div style={{width: '85%', display: 'flex', justifyContent: 'center'}}>
//         <p style={{fontSize: '20px', textAlign: 'center'}}>
//             Será redirigido a la<br/>
//             pasarela de pago
//         </p>
//     </div>
//     <a id='pagar' href={redirect} style={{visibility: 'hidden'}}>Pagar</a>
// </BodyContainer>
// </Section>
