import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import peruFlag from '../../../assets/peru-flag.svg';
import { instance } from '../../../Providers/api';
import { useDispatch, useSelector } from 'react-redux';
import { SET_USER } from '../../Redux/constants';
import Alert from '../../Components/Alert';
import BackNavButton from '../../../Components/elements/Navigation/backNavButton';
import dropdownArrow from "../../../assets/dropdown-arrow.svg";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
`;

const Form = styled.div`
background-color: white;
    font-family: 'Mulish';
    width: 100%;
    max-width: 386px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin: auto;
    @media (max-width: 420px) {
        max-width: 328px;
    }
    .comment{
    text-align: center;
    font-size: 12px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    }
`;


const Button = styled.button`
    cursor: pointer;
    color: white; 
    background-color: ${localStorage.getItem('color')}; 
    height: 40px; 
    width: 100%; 
    outline: none; 
    border: none;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 18px;
`;

const CompletarDatos = () => {
    const [openSelect, setOpenSelect] = useState(false);
    const dispatch = useDispatch();
    const [inputDateActive, setInputDateActive] = useState(false);
    const [datos, setDatos] = useState({
        tipoDocumento: 2,
        numeroDocumento: '',
        telefono: '',
        fechaNac: '',
    });
    const helpers = useSelector((state: any) => state.helpers);
    const logo = localStorage.getItem('logo');
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const listCodArea=[
        { cod: "+51", pais: "Perú",img:peruFlag}, 
    ]
    const [codAreaSelected, setCodAreaSelected] = useState<any>(listCodArea[0]);

    //eslint-disable-next-line
    useLayoutEffect(() => {instance.defaults.headers.common.Authorization = `Bearer ${helpers.bearer}`}, [])
    const openOrCloseSelect = () => {
        setOpenSelect(!openSelect);
    }

    return (
        <>
            {success ? <Alert setShow={setSuccess} title='¡Sesión iniciada con éxito!' image={'success'} backUrl={-1} borderColor={'#0DCA64'}/> : null}
            {fail ? <Alert setShow={setFail} title='¡Oops! Ocurrió un error al iniciar sesión.' image={'fail'} borderColor={'#CA450D'}/> : null}
            <div style={{ backgroundColor: '#ffffff'}}>
                    <Container>
                    <div style={{paddingTop:'28px',paddingLeft:'36px'}}>
                
                <BackNavButton text='Regístrate' url={""}/>
                </div>
                        
                        <Form>
                        <div style={{display:'flex',justifyContent:'center'}}> 
                        {logo?(<img src={logo || ''} alt='logo' referrerPolicy="no-referrer"
                                style={{
                                    objectFit: 'contain',
                                    maxHeight: '200px',
                                    maxWidth: '300px',
                                    marginBottom: '32px',
                                }}
                        />):null}
                        </div>
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap:"24px"}}>
                                <p className='comment'> Completa estos datos adicionales para poder realizar tu reserva.</p>
                                
                                <div style={{width: '100%',display:'flex'}}>                   
                                    <SelectDocument>
                                    <select value={datos.tipoDocumento} onChange={(e: any) => setDatos({...datos, tipoDocumento: e.target.value})}>
                                        <option value={2}>DNI</option>        
                                        <option value={1}>CE</option>        
                                        <option value={0}>Otro</option>        
                                    </select>
                                    </SelectDocument>

                                    <Input color={"#77767A"}>
                                    <input placeholder='Número de documento' type='text' value={datos.numeroDocumento} onChange={(e: any) => setDatos({...datos, numeroDocumento: e.target.value})}/>
                                    
                                    </Input>
                                </div>
                                <InputNumber color={"#77767A"}>
                            <div>
                                <div className='codArea' onClick={()=>openOrCloseSelect()}>
                                    <div>
                                        <img src={codAreaSelected.img} alt={codAreaSelected.pais} />
                                    </div>
                                    <button> <img src={dropdownArrow} alt="open-dropDown" /></button>
                                    <div className='numberCod'>
                                        {codAreaSelected.cod}
                                    </div>
                                    </div>
                                <div className={openSelect?'background':'nobackground'}>
                                {listCodArea.map((item, index) => {
                                return <div className='choise' 
                                key={index} onClick={()=>setCodAreaSelected(item)}>
                                 <img src={item.img} alt={item.pais} />
                                  {item.cod}
                                </div>})
                                }
                                </div>
                            </div>
                            <input placeholder='' type='text' value={datos.telefono} onChange={(e: any) => setDatos({...datos, telefono: e.target.value})}/>
                            
                            </InputNumber>

                            <InputDate color={'#77767A'}>
                        
                            <input placeholder='Fecha de nacimiento' onClick={()=>setInputDateActive(true)} type={inputDateActive?'date':'text'} value={datos.fechaNac} onChange={(e: any) => setDatos({...datos, fechaNac: e.target.value})}/>
                            </InputDate>

                                <Button onClick={() => {
                                    instance.post('completardatostlfydct', {
                                        numeroDocumento: datos.numeroDocumento,
                                        tipoDocumento: datos.tipoDocumento,
                                        telefono: codAreaSelected.cod.substring(1) + datos.telefono,
                                        fechaNacimiento: datos.fechaNac,
                                    })
                                    .then(() => instance.get('datausuario'))
                                    .then(res => {
                                        dispatch({
                                        type: SET_USER,
                                        payload: { ...res.data.data }});
                                        if(res.data.data.dataDctoYNrTelf) setSuccess(true); else setFail(true);
                                    })
                                }}>
                                    Confirmar
                                </Button>
                            </div>
                        </Form>
                    </Container>
            </div>
        </>
    )
}

export default CompletarDatos;

const Input= styled.div<{ color: string }>`
border-radius: 4px;
border: 1px solid ${props => props.color};
height: 48px;
width: 100%;
display: flex;
justify-content: space-between;
padding:  10px 12px 10px 12px;
box-sizing: border-box;
.check{
    display: flex  ;
    justify-content: center;
    img{
        width: 20px;
    }
}
input{
    font-family: 'Mulish';
    background: none;
    outline: none;
    width: 100%;
    border: none;

    ::placeholder{
    color: #77767A;
    size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    }
}

`

const SelectDocument = styled.div`
select{
    border-radius: 4px;
    border: 1px solid #77767A;
    height: 48px;
    width: 75px;
    display: flex;
    justify-content: space-between;
    padding:  10px 12px 10px 12px;
    margin-right: 8px;
}
`

const InputNumber = styled.div<{ color: string }>`
font-family: 'Mulish';
border-radius: 4px;
border: 1px solid ${props => props.color};
height: 48px;
width: 100%;
display: flex;
justify-content: space-between;
padding:  10px 12px 10px 12px;
box-sizing: border-box;
.check{
    display: flex  ;
    justify-content: center;
    img{
        width: 20px;
    }
}
.codArea{
    width: 80px;
    padding: 1px 6px 0px 6px;
    display: flex;
    font-size: 14px;
    background:none;
    align-items: center;
    justify-content:space-between;
    
    img{
        display: flex;
    }
    button{
        height: 24px;
        background: none;
        border: none;
        outline: none;
    }
    .numberCod{
        color: #9D9D9D;
    }
}
.background{
    background: #ffffff;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
    position: sticky;
    box-sizing: unset;
    .choise{display:flex;
        padding: 2px 6px 2px 6px;
    justify-content:space-between;
    font-size:12px;
    align-items:center;
    cursor:pointer;
    :hover{
        background: #e4e4e4;
    }
    }
}
.nobackground{
    background: none;
    display: none;
}
input{
    font-family: 'Mulish';
    background:none;
    outline: none;
    width: 100%;
    border: none;

    ::placeholder{
    color: #77767A;
    size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    }
}

`
const InputDate = styled.div<{ color: string }>`
border-radius: 4px;
border: 1px solid ${props => props.color};
height: 48px;
width: 100%;
display: flex;
justify-content: space-between;
padding:  10px 12px 10px 12px;
box-sizing: border-box;
.check{
    display: flex  ;
    justify-content: center;
    img{
        width: 20px;
    }
}
input{
    cursor: text;
    font-family: 'Mulish';
    background: none;
    outline: none;
    width: 100%;
    border: none;

    ::placeholder{
    color: #77767A;
    size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

`